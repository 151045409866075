import React, { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useRouteError } from 'react-router-dom';
import { hooks } from 'App/ducks/userDuck';
import AppFallbackUI from './FallbackUI/AppFallbackUI';
import SceneFallbackUI from './FallbackUI/SceneFallbackUI';

const ErrorBoundary = ({ appFallbackUI }) => {
  const user = hooks.useUserSelector();
  const error = useRouteError();
  useEffect(() => {
    if (error) {
      Sentry.withScope(scope => {
        if (user) {
          scope.setUser({ email: user.email });
        }
        scope.setTag('UTILIHIVE', 'ERROR_BONDARY_ERROR');
        Sentry.captureException(error);
      });
    }
  }, [user, error]);
  return appFallbackUI ? <AppFallbackUI /> : <SceneFallbackUI />;
};

export default ErrorBoundary;
