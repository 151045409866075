import React, { useMemo } from 'react';
import List from '@mui/material/List';
import { USER_ROLES } from '../../domain/user';
import NavigationListItem from './NavigationListItem';
import { useFeatureToggles } from 'sharedComponents/FeatureToggle';

const NavigationList = ({ routes, userRoles: roles, modules, onClose }) => {
  const features = useFeatureToggles();
  const filteredRoutes = useMemo(
    () =>
      routes
        .filter(route => (route.featureToggle ? features[route.featureToggle] : true))
        .filter(route =>
          route.restrictRoleAccess === USER_ROLES.ADMIN ? roles.some(role => role.role === USER_ROLES.ADMIN) : true
        )
        .filter(route =>
          route.moduleNameForOrganisationAccess ? modules.includes(route.moduleNameForOrganisationAccess) : true
        ),
    [routes, roles, modules, features]
  );
  return (
    <List sx={{ p: 1 }}>
      {filteredRoutes.map(route => (
        <NavigationListItem key={route.path} onClose={onClose} {...route} />
      ))}
    </List>
  );
};

export default NavigationList;
