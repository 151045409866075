import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const AppFallback = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 400);
    return () => clearTimeout(timeout);
  }, []);
  return show && <LinearProgress />;
};

export default AppFallback;
