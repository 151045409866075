import { Selector } from 'extensible-duck';
import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'flowTraces';

const duck = createFetchDuck('HEARTBEAT', storeKey, null, 'heartbeat').extend({
  types: ['SET_FLOW_TRACES_META', 'SET_FLOW_TRACE_DETAILS'],
  initialState: {
    data: [],
    meta: {
      requestedResultMaxSize: 100,
      resultOffset: 0,
      actualResultSize: 0,
      totalNoOfMatches: 0,
    },
  },
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_FLOW_TRACES_META:
        return { ...state, meta: action.data };
      case types.SET_FLOW_TRACE_DETAILS:
        return {
          ...state,
          data: state.data.map(item => (item.id === action.id ? { ...item, detailsData: action.data } : item)),
        };
      default:
        return state;
    }
  },
  selectors: {
    meta: new Selector(selectors => state => selectors.entities(state).meta),
    getFlowTrace: new Selector(selectors => (state, id) => selectors.data(state).find(item => item.id === id)),
  },
  creators: ({ types }) => ({
    setMeta: data => ({ type: types.SET_FLOW_TRACES_META, data }),
    setFlowTraceDetails: (id, data) => ({ type: types.SET_FLOW_TRACE_DETAILS, id, data }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
