import Duck from 'extensible-duck';
import baseDuck from '../../util/genericEntityDuck/baseDuck';
import { types as usagePointsTypes } from './usagePointsDuck';
import { types as usagePointsUploadTypes } from './usagePointsUploadDuck';

export const storeKey = 'ecosystemsConfig';
const namespace = 'ghostwriter';
const duck = baseDuck().extend({
  namespace,
  store: storeKey,
  types: ['OPEN', 'CLOSE', 'SET_RADIUS', 'SET_CENTER', 'SET_NUMBER_OF_USAGE_POINTS'],
  initialState: {
    ecosystemId: undefined,
    isVisible: false,
    radius: 3000,
    center: {
      // Default to Oslo
      longitude: 10.749960109247777,
      latitude: 59.94985267747376,
    },
    numberOfUsagePoints: 100,
  },
  reducer: (state, action, { initialState, types }) => {
    switch (action.type) {
      case types.OPEN:
        return { ...state, isVisible: true, ecosystemId: action.ecosystemId };
      case types.CLOSE:
      case usagePointsTypes.CREATE_SUCCESS:
      case usagePointsUploadTypes.CREATE_SUCCESS:
        return initialState;
      case types.SET_RADIUS:
        return { ...state, radius: action.radius };
      case types.SET_CENTER:
        return { ...state, center: action.center };
      case types.SET_ECOSYSTEM_ID:
        return { ...state, ecosystemId: action.ecosystemId, ecosystemSelectorIsOpen: false };
      case types.SET_NUMBER_OF_USAGE_POINTS:
        return { ...state, numberOfUsagePoints: action.numberOfUsagePoints };
      default:
        return state;
    }
  },
  selectors: {
    root: state => state[namespace][storeKey],
    isVisible: new Duck.Selector(selectors => state => selectors.root(state).isVisible),
    radius: new Duck.Selector(selectors => state => selectors.root(state).radius),
    center: new Duck.Selector(selectors => state => selectors.root(state).center),
    numberOfUsagePoints: new Duck.Selector(selectors => state => selectors.root(state).numberOfUsagePoints),
    ecosystemId: new Duck.Selector(selectors => state => selectors.root(state).ecosystemId),
  },
  creators: ({ types }) => ({
    open: ecosystemId => ({ type: types.OPEN, ecosystemId }),
    close: () => ({ type: types.CLOSE }),
    setRadius: radius => ({ type: types.SET_RADIUS, radius }),
    setCenter: center => ({ type: types.SET_CENTER, center }),
    setNumberOfUsagePoints: numberOfUsagePoints => ({ type: types.SET_NUMBER_OF_USAGE_POINTS, numberOfUsagePoints }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
