import Duck from 'extensible-duck';
import createEntityDuck from '../../util/genericEntityDuck';

const namespace = 'settings';
const storeKey = 'organizationSettings';

const duck = createEntityDuck(namespace, storeKey, 'id', namespace).extend({
  initialState: {
    data: {},
  },
  selectors: {
    configuration: new Duck.Selector(selectors => state => selectors.data(state)),
    role: new Duck.Selector(selectors => state => selectors.data(state).role),
    isAdmin: new Duck.Selector(selectors => state =>
      selectors.role(state) === 'ADMIN' || selectors.role(state) === 'OWNER'
    ),
    isOwner: new Duck.Selector(selectors => state => selectors.role(state) === 'OWNER'),
  },
});

export { duck as default, storeKey };
export const { creators, reducer, types, selectors, initialState } = duck;
