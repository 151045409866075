import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'flowAccessesDiscrepancies';
const duck = createFetchDuck('HEARTBEAT', storeKey, 'flowAccessId', 'heartbeat').extend({
  initialState: {
    data: {
      accessesWithoutDeployments: [],
      deploymentsWithoutAccesses: [],
    },
  },
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
