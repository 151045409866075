import { createTheme } from '@mui/material';
import palette from './darkPalette';
import '@fontsource/roboto/latin.css';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(255, 255, 255, 0.1)`,
          borderRadius: '10px',
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .Mui-disabled': {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)',
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        liveAnnounce: {
          position: 'relative',
        },
      },
    },
  },
  palette,
});

export default theme;
