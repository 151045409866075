import Duck from 'extensible-duck';

import { baseDuck } from '../../util/genericEntityDuck';
import { types as ecosystemTypes } from './ecosystemDuck';
import { isEcosystemValid } from '../domain/ecosystem';

const storeKey = 'newEcosystem';
const namespace = 'ghostwriter';
const duck = baseDuck().extend({
  namespace,
  store: storeKey,
  types: ['OPEN', 'CLOSE', 'SET_NAME'],
  initialState: {
    isOpen: false,
    name: '',
  },
  reducer: (state, action, { types, initialState }) => {
    switch (action.type) {
      case types.OPEN:
        return { ...state, isOpen: true };

      case types.CLOSE:
      case ecosystemTypes.OPEN:
        return { ...state, isOpen: false };

      case types.SET_NAME:
        return { ...state, name: action.name };

      case ecosystemTypes.CREATE_SUCCESS:
        return { ...initialState };

      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    open: () => ({ type: types.OPEN }),
    close: () => ({ type: types.CLOSE }),
    setName: name => ({ type: types.SET_NAME, name }),
  }),
  selectors: {
    root: new Duck.Selector(selectors => state => state[namespace][storeKey]),
    name: new Duck.Selector(selectors => state => selectors.root(state).name),
    isOpen: new Duck.Selector(selectors => state => selectors.root(state).isOpen),
    isEcosystemValid: new Duck.Selector(selectors => state => {
      const event = selectors.root(state);
      return isEcosystemValid(event);
    }),
  },
});
export { duck as default, storeKey };
export const { creators, reducer, types, selectors } = duck;
