import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';
import GEDatePicker from '../GEDatePicker';

const TitleBar = ({ actions, title, showDateRangePicker = false }) => {
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        enableColorOnDark
        color="default"
        sx={geThemeFeature ? { backgroundColor: 'surface.2' } : {}}
      >
        <Toolbar>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            {title}
            {geThemeFeature && showDateRangePicker && <GEDatePicker />}
          </Typography>
          {actions}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

TitleBar.propTypes = {
  actions: PropTypes.node,
  title: PropTypes.node,
};

export default TitleBar;
