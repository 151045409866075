import { Selector } from 'extensible-duck';
import { baseDuck } from '../../util/genericEntityDuck';
import { v4 as randomUuid } from 'uuid';

export const storeKey = 'errors';

export const ERROR_TYPES = {
  FETCHING_ASSETS_ERROR: {
    id: 1,
    intlKey: 'SMOC-errors-fetching-assets-error',
  },
  FETCHING_ASSET_TYPES_ERROR: {
    id: 2,
    intlKey: 'SMOC-errors-fetching-asset-types-error',
  },
  FETCHING_EVENTS_ERROR: {
    id: 3,
    intlKey: 'SMOC-errors-fetching-events-error',
  },
};

const duck = baseDuck().extend({
  namespace: 'smoc',
  store: storeKey,
  types: ['ADD_ERROR', 'REMOVE_ERROR'],
  initialState: {
    errors: [],
  },
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.ADD_ERROR:
        if (state.errors.find(error => error.errorId === action.error.id)) {
          return state;
        }
        const newErrorList = state.errors.slice();
        newErrorList.push({
          id: randomUuid(),
          errorId: action.error.id,
          intlKey: action.error.intlKey,
        });
        return { ...state, errors: newErrorList };
      case types.REMOVE_ERROR:
        const filteredErrors = state.errors.filter(item => item.id !== action.id);
        return { ...state, errors: filteredErrors };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    addError: error => ({ type: types.ADD_ERROR, error }),
    removeError: id => ({ type: types.REMOVE_ERROR, id }),
  }),
  selectors: {
    root: state => state.smoc[storeKey],
    errors: new Selector(selectors => state => selectors.root(state).errors),
  },
});
export default duck;
export const { creators, reducer, types, selectors } = duck;
