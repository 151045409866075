import Duck from 'extensible-duck';
import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'eventScenario';
const duck = createFetchDuck('ghostwriter', storeKey, 'eventScenarioId', 'ghostwriter').extend({
  types: [
    'SET_DEMO_DURATION',
    'START_DEMO',
    'START_DEMO_SUCCESS',
    'START_DEMO_FAILED',
    'SET_LIST_METADATA',
    'TOGGLE_CREATING',
  ],
  initialState: (_, initialState) => ({
    ...initialState,
    demoDuration: undefined,
    isStartingDemo: false,
    isCreating: false,
    listMetaData: undefined,
  }),
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.TOGGLE_CREATING:
        return { ...state, isCreating: !state.isCreating };

      case types.CREATE_SUCCESS:
        return { ...state, isCreating: false };

      case types.START_DEMO:
        return { ...state, isStartingDemo: true };

      case types.START_DEMO_SUCCESS:
        return { ...state, isStartingDemo: false };

      case types.START_DEMO_FAILED:
        return { ...state, isStartingDemo: false, error: action.error };

      case types.SET_LIST_METADATA:
        return { ...state, listMetaData: action.listMetaData };

      case types.SET_DEMO_DURATION:
        return {
          ...state,
          demoDuration: action.demoDuration,
        };

      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    setDemoDuration: demoDuration => ({ type: types.SET_DEMO_DURATION, demoDuration }),
    startDemo: (eventScenarioId, duration) => ({ type: types.START_DEMO, eventScenarioId, duration }),
    startDemoSuccess: () => ({ type: types.START_DEMO_SUCCESS }),
    startDemoFailed: error => ({ type: types.START_DEMO_FAILED, error }),
    setListMetaData: listMetaData => ({ type: types.SET_LIST_METADATA, listMetaData }),
    toggleCreating: () => ({ type: types.TOGGLE_CREATING }),
  }),
  selectors: {
    demoDuration: new Duck.Selector(selectors => state => selectors.entities(state).demoDuration),
    isStartingDemo: new Duck.Selector(selectors => state => selectors.entities(state).isStartingDemo),
    isCreating: new Duck.Selector(selectors => state => selectors.entities(state).isCreating),
    listMetaData: new Duck.Selector(selectors => state => selectors.entities(state).listMetaData),
  },
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
