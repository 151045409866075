import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { shallowEqual, useSelector } from 'react-redux';
import { selectors as configurationSelectors } from '../../../ducks/configurationDuck';
import { getItem, setItem } from 'util/localStorage';

const THEME_TOAST_KEY = 'UTILIHIVE:THEME_TOAST';

const SlideTransition = props => <Slide {...props} direction="down" />;

const ThemeChange = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const configuration = useSelector(state => configurationSelectors.configuration(state), shallowEqual);
  const [open, setOpen] = useState(!getItem(THEME_TOAST_KEY));
  const onClose = useCallback(() => {
    setOpen(false);
    setItem(THEME_TOAST_KEY, 'true');
  }, []);
  const onSettingsClick = useCallback(() => {
    onClose();
    navigate('/settings/user');
  }, [onClose, navigate]);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={null}
      message={intl.formatMessage({ id: 'Toasts-ThemeChange-message' }, { theme: configuration.theme })}
      TransitionComponent={SlideTransition}
      action={
        <>
          <Button color="secondary" size="small" onClick={onSettingsClick}>
            <FormattedMessage id="Toasts-ThemeChange-action" defaultMessage="User settings" />
          </Button>
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default ThemeChange;
