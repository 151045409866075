import { v4 as randomUuid } from 'uuid';

const sentMessages = [];
const receivedMessages = [];

const channelName = 'utilihive-ui';
const channelNameRegister = 'utilihive-ui-monitor';

const emptyChannel = { close: () => {} };

export const sendMessage = data => {
  try {
    const channel = new BroadcastChannel(channelName);
    const uuid = randomUuid();
    sentMessages.push(uuid);
    channel.postMessage({
      id: uuid,
      data,
    });
    channel.close();
  } catch (e) {}
};

const onmessage = (module, callback) => event => {
  try {
    const { data } = event;
    if (sentMessages.indexOf(data.id) < 0 && receivedMessages.indexOf(data.id) < 0) {
      receivedMessages.push(data.id);
      if (!module) {
        callback(data.data);
      } else if (data.data.module === module) {
        callback(data.data);
      }
    }
  } catch (e) {
    return emptyChannel;
  }
};

export const receiveMessage = (module, callback) => {
  try {
    const channel = new BroadcastChannel(channelName);
    channel.onmessage = onmessage(module, callback);
    return channel;
  } catch (e) {
    return emptyChannel;
  }
};

export const registerBroadcast = () => {
  try {
    const channel = new BroadcastChannel(channelNameRegister);
    channel.onmessage = e => {
      if (e.data.type === 'HOLA') {
        channel.postMessage({ type: 'ACK', id: e.data.id });
      }
    };
    return channel;
  } catch (e) {
    return emptyChannel;
  }
};

export const getMonitors = () =>
  new Promise((resolve, reject) => {
    let monitors = 0;
    const channel = new BroadcastChannel(channelNameRegister);
    const uuid = randomUuid();
    channel.postMessage({ type: 'HOLA', id: uuid });
    channel.onmessage = e => {
      if (e.data.type === 'ACK' && e.data.id === uuid) {
        monitors++;
      }
    };
    setTimeout(() => resolve(monitors), 1000);
  });
