import { combineReducers } from 'redux';
import { storeKey as organizationsStoreKey, reducer as organizationsReducer } from './organizationsDuck';
import { storeKey as organizationStoreKey, reducer as organizationReducer } from './organizationDuck';
import {
  storeKey as organizationSettingsStoreKey,
  reducer as organizationSettingsReducer,
} from './organizationSettingsDuck';
import { storeKey as organizationUsersStoreKey, reducer as organizationUsersReducer } from './organizationUsersDuck';
import { storeKey as existingUsersStoreKey, reducer as existingUsersReducer } from './existingUsersDuck';
import { storeKey as externalUsersStoreKey, reducer as externalUsersReducer } from './externalUsersDuck';
import { storeKey as auditStoreKey, reducer as auditReducer } from './auditDuck';
import { storeKey as connectivityStoreKey, reducer as connectivityReducer } from './connectivityDuck';
import { storeKey as featureStoreKey, reducer as featureReducer } from './featuresDuck';

export default combineReducers({
  [organizationsStoreKey]: organizationsReducer,
  [organizationStoreKey]: organizationReducer,
  [organizationUsersStoreKey]: organizationUsersReducer,
  [existingUsersStoreKey]: existingUsersReducer,
  [externalUsersStoreKey]: externalUsersReducer,
  [organizationSettingsStoreKey]: organizationSettingsReducer,
  [auditStoreKey]: auditReducer,
  [connectivityStoreKey]: connectivityReducer,
  [featureStoreKey]: featureReducer,
});
