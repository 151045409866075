import React, { useCallback, useState } from 'react';
import Popover from '@mui/material/Popover';
import DateIcon from '@mui/icons-material/CalendarToday';
import OutlinedInput from '@mui/material/OutlinedInput';
import DateRangePicker from './DateRangePicker';
import { useDateContext } from '../FilterBar/FancyDateSelect/utils/DateContext';
import InputAdornment from '@mui/material/InputAdornment';
import { useIntl } from 'react-intl';
import { darken, lighten } from '@mui/material/styles';

const DateRangePickerPopover = () => {
  const intl = useIntl();
  const { dates } = useDateContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const open = Boolean(anchorEl);
  const id = open ? 'date-range-picker-popover' : undefined;

  return (
    <>
      <OutlinedInput
        id="date-input"
        sx={theme => ({
          ml: 1,
          width: '250px',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.default
              : darken(theme.palette.background.default, 0.15),
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? lighten(theme.palette.background.default, 0.2)
                : darken(theme.palette.background.default, 0.2),
          },
        })}
        onClick={handleClick}
        readOnly={true}
        size="small"
        value={`${intl.formatDate(dates?.fromDate)} - ${intl.formatDate(dates?.toDate)}`}
        endAdornment={
          <InputAdornment position="end">
            <DateIcon fontSize="small" />
          </InputAdornment>
        }
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ paper: { sx: theme => ({ borderRadius: '10px' }) } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <DateRangePicker handleClose={handleClose} />
      </Popover>
    </>
  );
};

export default DateRangePickerPopover;
