import { combineReducers } from 'redux';

import { reducer as tasks, storeKey as tasksKey } from './tasksDuck';
import { reducer as users, storeKey as usersKey } from './usersDuck';

const tasksReducer = combineReducers({
  [tasksKey]: tasks,
  [usersKey]: users,
});

export default tasksReducer;
