import React, { useCallback } from 'react';
import moment from 'moment/moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { object, date } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, Stack } from '@mui/material';
import QuickDates from './QuickDates';
import StartingDate from './StartingDate';
import EndingDate from './EndingDate';
import { setTimeHistory } from '../../App/api/localStorage';
import { useDateContext } from '../FilterBar/FancyDateSelect/utils/DateContext';

const formSchema = object().shape({
  fromDate: date().test({
    test(value, ctx) {
      const fromDate = moment(value);
      const toDate = moment(ctx.options.parent.toDate);
      if ((fromDate.isSame(toDate) && toDate.hour() < fromDate.hour()) || toDate.isBefore(fromDate)) {
        return ctx.createError({ message: 'validation.form.field.date.is.before.other' });
      }
      return true;
    },
  }),
  toDate: date().test({
    test(value, ctx) {
      const toDate = moment(value);
      const fromDate = moment(ctx.options.parent.fromDate);
      if ((toDate.isSame(fromDate) && fromDate.hour() > toDate.hour()) || fromDate.isAfter(toDate)) {
        return ctx.createError({ message: 'validation.form.field.date.is.after.other' });
      }
      return true;
    },
  }),
});

const DateRangeForm = ({ handleClose }) => {
  const intl = useIntl();
  const { dates, updateDates } = useDateContext();
  const { handleSubmit, watch, control, getValues, formState } = useForm({
    defaultValues: {
      fromDate: dates ? dates.fromDate : moment().subtract(1, 'hours'),
      toDate: dates ? dates.toDate : moment(),
    },
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    criteriaMode: 'firstError',
  });
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');
  const onSubmitCallback = useCallback(
    data => {
      const { fromDate, toDate } = data;
      updateDates({ fromDate: moment(fromDate), toDate: moment(toDate) });
      setTimeHistory({ fromDate: moment(fromDate), toDate: moment(toDate) });
      handleClose();
    },
    [updateDates, handleClose]
  );

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', minWidth: '520px' }}>
        <StartingDate fromDate={fromDate} toDate={toDate} control={control} getValues={getValues} />
        <EndingDate fromDate={fromDate} toDate={toDate} control={control} getValues={getValues} />
      </Box>
      {formState.errors['fromDate']?.message && (
        <FormHelperText error>{intl.formatMessage({ id: formState.errors.fromDate.message })}</FormHelperText>
      )}
      {formState.errors['toDate']?.message && (
        <FormHelperText error>{intl.formatMessage({ id: formState.errors.toDate.message })}</FormHelperText>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Stack spacing={2} direction="row">
          <Button color="inherit" variant="contained" size="small" onClick={handleClose}>
            {intl.formatMessage({ id: 'DateRangePicker-button-cancel', defaultMessage: 'Cancel' })}
          </Button>
          <Button color="primary" variant="contained" size="small" onClick={handleSubmit(onSubmitCallback)}>
            {intl.formatMessage({ id: 'DateRangePicker-button-setDates', defaultMessage: 'Set Dates' })}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

const DateRangePicker = ({ handleClose }) => (
  <Box sx={{ display: 'flex' }}>
    <Box sx={{ display: 'flex', width: '200px', backgroundColor: 'surface.2' }}>
      <QuickDates handleClose={handleClose} />
    </Box>
    <Box sx={{ backgroundColor: 'surface.1', p: 2 }}>
      <DateRangeForm handleClose={handleClose} />
    </Box>
  </Box>
);

export default DateRangePicker;
