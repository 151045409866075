import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'usagepoint';
const duck = createFetchDuck('smoc', storeKey, 'id', 'smoc').extend({
  initialState: {
    data: {},
  },
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
