import React from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemButton from '@mui/material/ListItemButton';
import { lighten, alpha } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';

const ExternalNavigationListItem = ({ onClose, path, intlMessage, Icon }) => {
  return (
    <ListItemButton
      target="_blank"
      component="a"
      href={path}
      onClick={onClose}
      sx={theme => ({
        borderRadius: '5px',
        padding: {
          xs: '16px',
          md: '11px',
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? alpha(theme.palette.primary.main, 0.3)
              : lighten(theme.palette.primary.main, 0.5),
        },
      })}
    >
      {Icon}
      <ListItemText sx={{ py: 0, px: 1 }} primary={<FormattedMessage {...intlMessage} />} />
    </ListItemButton>
  );
};

export default ExternalNavigationListItem;
