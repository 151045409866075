import Duck, { Selector } from 'extensible-duck';
import withHooks from '../../util/genericEntityDuck/withHooks';
import { tokenDuck } from '../../store/jwtMiddleware';

const storeKey = 'auth';
const namespace = 'app';

const duck = withHooks(
  new Duck({
    namespace,
    store: storeKey,
    types: ['LOGIN_START', 'LOGIN_SUCCESS', 'LOGIN_FAIL', 'LOGOUT'],
    initialState: {
      loading: false,
      loginFailed: false,
      error: undefined,
    },
    reducer: (state, action, { types, initialState }) => {
      switch (action.type) {
        case types.LOGIN_START:
          return { ...state, loading: true, loginFailed: false, loggedIn: false, error: undefined };
        case types.LOGIN_SUCCESS:
          return {
            ...initialState,
          };

        case types.LOGIN_FAIL:
          return {
            ...initialState,
            loginFailed: true,
            error: action.error,
          };

        case types.LOGOUT:
          return initialState;

        default:
          return state;
      }
    },
    selectors: {
      root: state => state[namespace][storeKey],
      token: state => tokenDuck.selectors.token(state),
      isLoading: new Selector(selectors => state => selectors.root(state).loading),
      error: new Selector(selectors => state => selectors.root(state).error),
    },
    creators: ({ types }) => ({
      loginStart: (email, password) => ({ type: types.LOGIN_START, email, password }),
      loginSuccess: () => ({ type: types.LOGIN_SUCCESS }),
      loginError: (error = {}) => ({ type: types.LOGIN_FAIL, error: error.message }),
      logout: () => ({ type: types.LOGOUT }),
    }),
  })
);

export { duck as default, storeKey, namespace };
export const { creators, reducer, types, selectors, hooks } = duck;
