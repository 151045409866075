import { createTheme } from '@mui/material';
import palette from './lightPalette';
import '@fontsource/roboto/latin.css';

const theme = createTheme({
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        liveAnnounce: {
          position: 'relative',
        },
      },
    },
  },
  palette,
});

export default theme;
