import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import AppContainer from './scenes/App';
import initializeStore from '../store';
import ThemeProvider from './theme';
import 'App/config/licenseModule-ag-grid';

const store = initializeStore();

const Application = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <CssBaseline />
        <AppContainer />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

export default Application;
