export const setItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {}
};

export const getItem = key => {
  try {
    return window.localStorage.getItem(key) || null;
  } catch (e) {
    return null;
  }
};

export const removeItem = key => {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {}
};

export const setJsonItem = (key, value) => {
  setItem(key, JSON.stringify(value));
};

export const getJsonItem = key => {
  return JSON.parse(getItem(key));
};
