import Duck from 'extensible-duck';
import moment from 'moment';

import { baseDuck } from '../../util/genericEntityDuck';
import { types as ecosystemTypes } from './ecosystemDuck';
import { isSimulationValid } from '../domain/simulations';
import { simulationDateFormat } from '../presentation/simulations';
import { types as simulationTypes } from './simulationDuck';

const storeKey = 'newSimulation';
const namespace = 'ghostwriter';
const duck = baseDuck().extend({
  namespace,
  store: storeKey,
  types: [
    'OPEN',
    'CLOSE',
    'SET_NAME',
    'SET_END_DATE',
    'SET_TIME_START',
    'SET_TIME_END',
    'SET_USAGE_SCENARIO',
    'SET_EVENT_SCENARIO',
  ],
  initialState: {
    isOpen: false,
    name: '',
    usageScenarioId: undefined,
    eventScenarioId: undefined,
    startDate: moment(),
    endDate: moment().add(1, 'day'),
    isValid: false,
  },
  reducer: (state, action, { types, initialState }) => {
    switch (action.type) {
      case types.OPEN:
        return { ...state, isOpen: true };

      case types.CLOSE:
      case ecosystemTypes.OPEN:
        return { ...state, isOpen: false };

      case types.SET_NAME:
        return { ...state, name: action.name };

      case types.SET_TIME_START:
        return {
          ...state,
          startDate: action.date,
          endDate: state.endDate.isBefore(action.date) ? action.date : state.endDate,
        };

      case types.SET_TIME_END:
        return {
          ...state,
          endDate: action.date,
          startDate: state.startDate.isAfter(action.date) ? action.date : state.startDate,
        };

      case types.SET_USAGE_SCENARIO:
        return { ...state, usageScenarioId: action.usageScenarioId };

      case types.SET_EVENT_SCENARIO:
        return { ...state, eventScenarioId: action.eventScenarioId };

      case simulationTypes.CREATE_SUCCESS:
        return {
          ...initialState,
        };

      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    open: () => ({ type: types.OPEN }),
    close: () => ({ type: types.CLOSE }),
    setName: name => ({ type: types.SET_NAME, name }),
    setStartDate: dateString => ({ type: types.SET_TIME_START, date: moment(dateString, simulationDateFormat) }),
    setEndDate: dateString => ({ type: types.SET_TIME_END, date: moment(dateString, simulationDateFormat) }),
    setEventScenario: eventScenarioId => ({ type: types.SET_EVENT_SCENARIO, eventScenarioId }),
    setUsageScenario: usageScenarioId => ({ type: types.SET_USAGE_SCENARIO, usageScenarioId }),
  }),
  selectors: {
    root: new Duck.Selector(selectors => state => state[namespace][storeKey]),
    name: new Duck.Selector(selectors => state => selectors.root(state).name),
    isOpen: new Duck.Selector(selectors => state => selectors.root(state).isOpen),
    startDate: new Duck.Selector(selectors => state => selectors.root(state).startDate),
    endDate: new Duck.Selector(selectors => state => selectors.root(state).endDate),
    eventScenarioId: new Duck.Selector(selectors => state => selectors.root(state).eventScenarioId),
    usageScenarioId: new Duck.Selector(selectors => state => selectors.root(state).usageScenarioId),
    isValid: new Duck.Selector(selectors => state => isSimulationValid(selectors.root(state))),
  },
});
export { duck as default, storeKey };
export const { creators, reducer, types, selectors } = duck;
