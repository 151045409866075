import React from 'react';
import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';
import { getProperty as get } from 'dot-prop';

const TextFieldInput = ({ registerProps, errors, InputLabelProps = { shrink: true }, variant, ...props }) => {
  const { ref, name, ...rest } = registerProps;
  return (
    <TextField
      InputLabelProps={InputLabelProps}
      error={!!get(errors, name)}
      inputRef={ref}
      name={name}
      variant={variant || 'standard'}
      {...props}
      {...rest}
      helperText={
        get(errors, name) &&
        (get(errors, name).message ? (
          <FormattedMessage id={get(errors, name).message} />
        ) : (
          <FormattedMessage id="Admin-label-valueMissing" />
        ))
      }
    />
  );
};

export default TextFieldInput;
