export const convertLatLngKeysToLatitudeLongitudeKeys = latLngObject => ({
  longitude: latLngObject.lng,
  latitude: latLngObject.lat,
});

export const createConfigurationsBoundingBox = (center, bounds) => ({
  center: convertLatLngKeysToLatitudeLongitudeKeys(center),
  southWest: convertLatLngKeysToLatitudeLongitudeKeys(bounds.getSouthWest()),
  northEast: convertLatLngKeysToLatitudeLongitudeKeys(bounds.getNorthEast()),
});

export const getGridParamsForZoomLevel = zoomLevel => {
  const gridParams = {
    20: { gridColumns: 14, gridRows: 10, usagePointGroupThreshold: 2 },
    19: { gridColumns: 14, gridRows: 10, usagePointGroupThreshold: 2 },
    18: { gridColumns: 14, gridRows: 10, usagePointGroupThreshold: 2 },
    17: { gridColumns: 12, gridRows: 10, usagePointGroupThreshold: 2 },
    16: { gridColumns: 12, gridRows: 10, usagePointGroupThreshold: 2 },
    15: { gridColumns: 12, gridRows: 10, usagePointGroupThreshold: 2 },
    14: { gridColumns: 10, gridRows: 7, usagePointGroupThreshold: 2 },
    13: { gridColumns: 10, gridRows: 7, usagePointGroupThreshold: 2 },
    12: { gridColumns: 9, gridRows: 6, usagePointGroupThreshold: 2 },
    11: { gridColumns: 9, gridRows: 6, usagePointGroupThreshold: 2 },
    10: { gridColumns: 8, gridRows: 5, usagePointGroupThreshold: 2 },
    9: { gridColumns: 8, gridRows: 3, usagePointGroupThreshold: 1000 },
  };

  return gridParams[zoomLevel] || gridParams[9];
};

export const getRandomPointInBounds = ({
  boundingBox: {
    northEast: { longitude: northEastLng, latitude: northEastLat } = {},
    southWest: { longitude: southWestLng, latitude: southWestLat } = {},
  } = {},
} = {}) => {
  const height = northEastLng - Math.abs(southWestLng);
  const width = northEastLat - Math.abs(southWestLat);

  const x = southWestLat + Math.random() * width;
  const y = southWestLng + Math.random() * height;

  return [x, y];
};
