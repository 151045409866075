const CONFIRM_LOGOUT_DISABLED_LOCAL_STORAGE_KEY = 'confirmLogout';
const ACTIVE_ROLE_KEY = 'activeRole';

const ASSET_MODAL_STORAGE_KEY = 'ASSET_MODAL';
const FULL_SCREEN_STORAGE_KEY = `${ASSET_MODAL_STORAGE_KEY}:FULLSCREEN`;

export const setDisableConfirmLogout = (isDisabled = false) =>
  window.localStorage.setItem(CONFIRM_LOGOUT_DISABLED_LOCAL_STORAGE_KEY, Boolean(isDisabled));

export const isConfirmLogoutDisabled = () =>
  Boolean(window.localStorage.getItem(CONFIRM_LOGOUT_DISABLED_LOCAL_STORAGE_KEY)) === true;

export const getFullScreenSetting = () => !!localStorage.getItem(FULL_SCREEN_STORAGE_KEY);
export const setFullScreenSetting = fullScreen => {
  if (fullScreen) {
    localStorage.setItem(FULL_SCREEN_STORAGE_KEY, 'true');
  } else {
    localStorage.removeItem(FULL_SCREEN_STORAGE_KEY);
  }
};

export const getActiveRole = () => {
  const activeRoleRaw = localStorage.getItem(ACTIVE_ROLE_KEY);
  return activeRoleRaw ? JSON.parse(activeRoleRaw) : null;
};

export const setActiveRole = role => {
  localStorage.setItem(ACTIVE_ROLE_KEY, JSON.stringify(role));
};

export const getTimeHistory = () => {
  const timeHistory = localStorage.getItem('TIME_HISTORY');
  return timeHistory ? JSON.parse(timeHistory).reverse() : [];
};

export const setTimeHistory = dateRange => {
  const timeHistory = getTimeHistory();
  if (timeHistory.length < 10) {
    timeHistory.push(dateRange);
    localStorage.setItem('TIME_HISTORY', JSON.stringify(timeHistory));
  } else {
    timeHistory.pop();
    timeHistory.push(dateRange);
    localStorage.setItem('TIME_HISTORY', JSON.stringify(timeHistory));
  }
};

const getRefreshIntervalSettings = () => {
  const refreshIntervalSettings = localStorage.getItem('REFRESH_INTERVAL_SETTINGS');
  return refreshIntervalSettings ? JSON.parse(refreshIntervalSettings) : [];
};

export const setRefreshIntervalSetting = ({ key, pause, value, timeUnit }) => {
  const refreshIntervalSettings = getRefreshIntervalSettings();
  const existingRefreshIntervalSettingIndex = refreshIntervalSettings.findIndex(setting => setting.key === key);
  if (existingRefreshIntervalSettingIndex >= 0) {
    refreshIntervalSettings[existingRefreshIntervalSettingIndex] = {
      key: key,
      pause: pause,
      value: value,
      timeUnit: timeUnit,
    };
  } else {
    refreshIntervalSettings.push({ key: key, pause: pause, value: value, timeUnit: timeUnit });
  }
  localStorage.setItem('REFRESH_INTERVAL_SETTINGS', JSON.stringify(refreshIntervalSettings));
};

export const getRefreshIntervalSetting = ({ key }) => {
  const refreshIntervalSettings = getRefreshIntervalSettings();
  const refreshIntervalSetting = refreshIntervalSettings.find(setting => setting.key === key);
  return refreshIntervalSetting ? refreshIntervalSetting : null;
};
