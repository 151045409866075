import createFetchDuck from '../../util/genericEntityDuck';

const namespace = 'settings';
const storeKey = 'connectivity';

const duck = createFetchDuck(namespace, storeKey, 'id', namespace).extend({
  initialState: {
    data: {},
  },
});

export { duck as default, storeKey };
export const { creators, reducer, types, selectors, initialState } = duck;
