import React from 'react';
import { useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const SceneFallbackUI = () => {
  const location = useLocation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        pt: 4,
      }}
    >
      <Paper
        sx={{
          p: 4,
        }}
      >
        <Typography sx={{ mb: 2 }} variant="h4">
          <FormattedMessage
            id={'ErrorBoundary-SceneFallbackUI-title'}
            defaultMessage={"We're sorry - something's gone wrong."}
          />
        </Typography>
        <Typography sx={{ mb: 2 }} variant="subtitle1">
          <FormattedMessage
            id={'ErrorBoundary-SceneFallbackUI-content'}
            defaultMessage={
              'A report has been sent to our team. If the error continues to occur please contact us for help.'
            }
          />
        </Typography>
        <Button variant="outlined" color="primary" component="a" href={location.pathname}>
          <FormattedMessage id={'ErrorBoundary-SceneFallbackUI-button'} defaultMessage={'Try refreshing the page'} />
        </Button>
      </Paper>
    </Box>
  );
};

export default SceneFallbackUI;
