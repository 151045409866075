import React, { useCallback, useEffect, useState } from 'react';
import { useLinkClickHandler, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';

import Logout from './Logout';
import { navRoutes } from '../../config/routes';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';
import TasksFilterBarButton from 'Tasks/components/TasksFilterBarButton/TasksFilterBarButtonContainer';
import Tasks from 'Tasks/components/Tasks/TasksContainer';
import { useZitadelContext } from '../ZitadelProvider';

const Navigation = ({ organizationShortName, fetchFeatures, logout, modules }) => {
  const intl = useIntl();
  const showTasks = useFeatureToggle(FEATURE_TOGGLES.TASKS);
  const foundation = useFeatureToggle(FEATURE_TOGGLES.FOUNDATION);
  const { signout: signoutZitadel } = useZitadelContext();

  const onProfileClick = useLinkClickHandler('/settings/user');
  useEffect(() => {
    if (organizationShortName && !foundation) {
      fetchFeatures();
    }
  }, [fetchFeatures, organizationShortName, foundation]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const current = location.pathname.split('/')[1];
  const onSelect = useCallback(
    e => {
      navigate('/' + e.target.value);
    },
    [navigate]
  );
  return (
    <>
      <AppBar>
        <Toolbar>
          <Typography variant="body1" component="div" sx={{ ml: 0, mr: 2, fontSize: 20 }}>
            <FormattedMessage id="general.gridos" defaultMessage="GridOS" />
          </Typography>
          <Typography variant="body1" component="div" sx={{ ml: 0, mr: 2, fontSize: 20 }}>
            <FormattedMessage id="general.connect" defaultMessage="Connect" />
          </Typography>
          <Select
            id="nav-bar-select"
            value={current}
            label="Module"
            onChange={onSelect}
            sx={{
              ml: 3,
              minWidth: 200,
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              color: 'text.labelwhite',
            }}
          >
            {navRoutes
              .filter(route =>
                route.moduleNameForOrganisationAccess ? modules.includes(route.moduleNameForOrganisationAccess) : true
              )
              .map(route => (
                <MenuItem key={route.path} value={route.path}>
                  <FormattedMessage {...route.intlMessage} />
                </MenuItem>
              ))}
          </Select>
          <Box sx={{ flexGrow: 3 }} />
          {showTasks && <TasksFilterBarButton />}
            <Tooltip title={intl.formatMessage({ id: 'Toasts-ThemeChange-action', defaultMessage: 'User settings' })}>
              <IconButton onClick={e => onProfileClick(e)} sx={{ ml: 1 }} color="inherit">
                <AccountCircle />
              </IconButton>
            </Tooltip>
          <Box sx={{ ml: 1, mr: 1, width: 0, borderLeft: '1px solid', borderColor: 'borders.moderate', height: 50 }} />
          <IconButton onClick={handleClick} color="inherit">
            <MoreVertIcon />
          </IconButton>
        </Toolbar>
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          <Logout logout={foundation ? signoutZitadel : logout} />
        </Menu>
      </AppBar>
      <Tasks />
    </>
  );
};

export default Navigation;
