import Duck from 'extensible-duck';
import createFetchDuck from '../../util/genericEntityDuck';
import { SIMULATION_STATE } from '../domain/simulations';

export const storeKey = 'simulation';
const duck = createFetchDuck('ghostwriter', storeKey, undefined, 'ghostwriter').extend({
  types: [
    'ABORT_SIMULATION',
    'ABORT_SIMULATION_SUCCESS',
    'ABORT_SIMULATION_FAILED',
    'START_SIMULATION',
    'START_SIMULATION_SUCCESS',
    'START_SIMULATION_FAILED',
    'SET_LIST_METADATA',
    'TOGGLE_CREATING',
    'UPDATE_SIMULATIONS',
  ],
  initialState: (_, initialState) => ({
    ...initialState,
    selectedSimulationId: undefined,
    isStartingSimulation: false,
    isAbortingSimulation: false,
    listMetaData: undefined,
    isCreating: false,
  }),
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_LIST_METADATA:
        return { ...state, listMetaData: action.listMetaData };

      case types.START_SIMULATION:
        return { ...state, isStartingSimulation: true };

      case types.START_SIMULATION_FAILED:
        return { ...state, isStartingSimulation: false, error: action.error };

      case types.ABORT_SIMULATION:
        return { ...state, isAbortingSimulation: true };

      case types.ABORT_SIMULATION_FAILED:
        return { ...state, isAbortingSimulation: false, error: action.error };

      case types.TOGGLE_CREATING: {
        return { ...state, isCreating: !state.isCreating };
      }

      case types.CREATE_SUCCESS: {
        return { ...state, isCreating: false };
      }

      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    startSimulation: simulationId => ({ type: types.START_SIMULATION, simulationId }),
    startSimulationSuccess: simulationId => ({ type: types.START_SIMULATION_SUCCESS, simulationId }),
    startSimulationFailed: error => ({ type: types.START_SIMULATION_FAILED, error }),
    abortSimulation: simulationId => ({ type: types.ABORT_SIMULATION, simulationId }),
    abortSimulationSuccess: simulationId => ({ type: types.ABORT_SIMULATION_SUCCESS, simulationId }),
    abortSimulationFailed: error => ({ type: types.ABORT_SIMULATION_FAILED, error }),
    setListMetaData: listMetaData => ({ type: types.SET_LIST_METADATA, listMetaData }),
    toggleCreating: () => ({ type: types.TOGGLE_CREATING }),
    updateSimulations: () => ({ type: types.UPDATE_SIMULATIONS }),
  }),
  selectors: {
    getActiveSimulations: new Duck.Selector(selectors => state =>
      selectors.data(state).filter(simulation => simulation.state !== SIMULATION_STATE.RUNNABLE)
    ),
    isCreating: new Duck.Selector(selectors => state => selectors.entities(state).isCreating),
    selectedSimulationId: new Duck.Selector(selectors => state => selectors.entities(state).selectedSimulationId),
    isStartingSimulation: new Duck.Selector(selectors => state => selectors.entities(state).isStartingSimulation),
    isAbortingSimulation: new Duck.Selector(selectors => state => selectors.entities(state).isAbortingSimulation),
    listMetaData: new Duck.Selector(selectors => state => selectors.entities(state).listMetaData),
  },
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
