import { init } from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

export const initializeSentry = () => {
  const dsn = window && window.app && window.app.SENTRY_URL;
  if (!dsn) {
    return;
  }
  init({
    dsn,
    release: 'utilihive-frontend@' + import.meta.env.VITE_VERSION,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
};
