import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SnackbarContent from '@mui/material/SnackbarContent';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Warning from '@mui/icons-material/Warning';
import { FormattedMessage } from 'react-intl';

const icons = {
  warning: Warning,
  success: CheckCircleIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const variantSx = {
  error: {
    backgroundColor: 'error.dark',
    color: '#ffffff',
  },
  info: {
    backgroundColor: 'info.main',
    color: '#ffffff',
  },
  success: {
    backgroundColor: 'primary.dark',
    color: '#ffffff',
  },
  warning: {
    backgroundColor: 'warning.dark',
    color: '#ffffff',
  },
};

const MessageBox = ({ message, messageId, values, variant, inline, slim, className, action, sx }) => {
  if (!message && !messageId) {
    return null;
  }
  const Icon = icons[variant];
  return (
    <SnackbarContent
      sx={[
        { maxWidth: '100%', width: '100%' },
        variantSx[variant],
        inline && { width: 'initial' },
        slim && { height: '37px' },
        sx,
      ]}
      className={className}
      action={action}
      message={
        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ fontSize: '20px', opacity: 0.9, mr: 1 }} />
          {message || <FormattedMessage id={messageId} values={values} />}
        </Box>
      }
    />
  );
};

MessageBox.propTypes = {
  variant: PropTypes.oneOf(['info', 'success', 'error', 'warning']),
  message: PropTypes.node,
  messageId: PropTypes.string,
  inline: PropTypes.bool,
  slim: PropTypes.bool,
  className: PropTypes.string,
};

MessageBox.defaultProps = {
  variant: 'error',
  messageId: undefined,
  message: undefined,
  values: undefined,
  inline: false,
  slim: false,
  className: undefined,
};

export default MessageBox;
