import { combineReducers } from 'redux';

import { reducer as deployedFlows, storeKey as deployedFlowsKey } from './deployedFlows';
import { reducer as metrics, storeKey as metricsKey } from './metricsDuck';
import { reducer as flowTraces, storeKey as flowTracesKey } from './flowTraces';
import { reducer as histograms, storeKey as histogramsKey } from './histogramsDuck';
import { reducer as serviceAccounts, storeKey as serviceAccountsKey } from './serviceAccountsDuck';
import { reducer as flowAccesses, storeKey as flowAccessesKey } from './flowAccessesDuck';
import { reducer as payloads, storeKey as payloadsKey } from './payloadsDuck';
import { reducer as flowSummary, storeKey as flowSummaryKey } from './flowSummary';
import { reducer as resources, storeKey as resourcesKey } from './resources';
import { reducer as resource, storeKey as resourceKey } from './resource';
import { reducer as resourceTypes, storeKey as resourceTypesKey } from './resourceTypes';
import { reducer as alerts, storeKey as alertsKey } from './alertsDuck';
import { reducer as organizationUsers, storeKey as organizationUsersKey } from './organizationUsersDuck';
import { reducer as secrets, storeKey as secretsKey } from './secrets';
import { reducer as secretsDetails, storeKey as secretsDetailsKey } from './secretsDetails';
import { reducer as beatbox, storeKey as beatboxKey } from './beatboxDuck';
import { reducer as beatboxFlows, storeKey as beatboxFlowsKey } from './beatboxFlowsDuck';
import { reducer as lookupTables, storeKey as lookupTablesKey } from './lookupTables';
import { reducer as lookupTableRows, storeKey as lookupTableRowsKey } from './lookupTableRows';
import {
  reducer as nonMessageSpecificOverview,
  storeKey as nonMessageSpecificOverviewKey,
} from './nonMessageSpecificOverviewDuck';
import {
  reducer as flowAccessesDiscrepancies,
  storeKey as flowAccessesDiscrepanciesKey,
} from './flowAccessesDiscrepanciesDuck';
import { reducer as reporting, storeKey as reportingKey } from './reportingDuck';

const heartbeat = combineReducers({
  [deployedFlowsKey]: deployedFlows,
  [metricsKey]: metrics,
  [flowTracesKey]: flowTraces,
  [histogramsKey]: histograms,
  [serviceAccountsKey]: serviceAccounts,
  [flowAccessesKey]: flowAccesses,
  [flowAccessesDiscrepanciesKey]: flowAccessesDiscrepancies,
  [flowSummaryKey]: flowSummary,
  [payloadsKey]: payloads,
  [resourcesKey]: resources,
  [resourceKey]: resource,
  [resourceTypesKey]: resourceTypes,
  [alertsKey]: alerts,
  [reportingKey]: reporting,
  [organizationUsersKey]: organizationUsers,
  [nonMessageSpecificOverviewKey]: nonMessageSpecificOverview,
  [secretsKey]: secrets,
  [secretsDetailsKey]: secretsDetails,
  [beatboxKey]: beatbox,
  [lookupTablesKey]: lookupTables,
  [lookupTableRowsKey]: lookupTableRows,
  [beatboxFlowsKey]: beatboxFlows,
});

export default heartbeat;
