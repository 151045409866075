const ERROR_CODES = {
  HTTP_RESPONSE_INVALID_CONTENT_TYPE: 'HTTP_RESPONSE_INVALID_CONTENT_TYPE',
  HTTP_RESPONSE_STATUS_NOT_2XX: 'HTTP_RESPONSE_STATUS_NOT_2XX',
  HTTP_RESPONSE_STATUS_UNAUTHORIZED: 'HTTP_RESPONSE_STATUS_UNAUTHORIZED',
  USER_AUTH_NOT_LOGGED_IN: 'USER_AUTH_NOT_LOGGED_IN',
  admin: {
    MISSING_AUTH_TOKEN: 'MISSING_AUTH_TOKEN',
  },
  app: {
    REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',
  },
};

export default ERROR_CODES;
