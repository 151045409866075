import createFetchDuck from '../../util/genericEntityDuck';
import { Selector } from 'extensible-duck';

const sortByFlowId = (a, b) => {
  if (a.flowId < b.flowId) return -1;
  if (a.flowId > b.flowId) return 1;
  return 0;
};

export const storeKey = 'reporting';
const duck = createFetchDuck('HEARTBEAT', storeKey, 'id', 'heartbeat').extend({
  types: [
    'SET_ALL_FLOWS',
    'SET_ALL_FLOWS_COMPARE_TIME_PERIOD',
    'REMOVE_ALL_FLOWS_COMPARE_TIME_PERIOD',
    'SET_COMPARE_FLOW',
    'REMOVE_COMPARE_FLOWS',
    'SET_COMPARE_TIME_PERIOD_FLOWS',
    'REMOVE_COMPARE_TIME_PERIOD_FLOWS',
  ],
  initialState: {
    allFlows: null,
    allFlowsCompareTimePeriod: null,
    compareFlows: [],
    compareTimePeriodFlows: [],
  },
  reducer(state, action, { types }) {
    switch (action.type) {
      case types.SET_ALL_FLOWS: {
        return { ...state, allFlows: action.data };
      }
      case types.SET_ALL_FLOWS_COMPARE_TIME_PERIOD: {
        return { ...state, allFlowsCompareTimePeriod: action.data };
      }
      case types.REMOVE_ALL_FLOWS_COMPARE_TIME_PERIOD: {
        return { ...state, allFlowsCompareTimePeriod: null };
      }
      case types.SET_COMPARE_FLOW: {
        const compareFlows = state.compareFlows.filter(compareFlow => compareFlow.flowId !== action.data.flowId);
        const flows = [...compareFlows, action.data];
        return { ...state, compareFlows: flows.sort(sortByFlowId) };
      }
      case types.REMOVE_COMPARE_FLOWS: {
        const compareFlows = state.compareFlows.filter(flowId => action.flowIds.includes(flowId));
        return { ...state, compareFlows: compareFlows.sort(sortByFlowId) };
      }
      case types.SET_COMPARE_TIME_PERIOD_FLOWS: {
        const compareTimePeriodFlows = state.compareTimePeriodFlows.filter(
          compareFlow => compareFlow.flowId !== action.data.flowId
        );
        const flows = [...compareTimePeriodFlows, action.data];
        return { ...state, compareTimePeriodFlows: flows.sort(sortByFlowId) };
      }
      case types.REMOVE_COMPARE_TIME_PERIOD_FLOWS: {
        const compareTimePeriodFlows = state.compareTimePeriodFlows.filter(flowId => action.flowIds.includes(flowId));
        return { ...state, compareTimePeriodFlows: compareTimePeriodFlows.sort(sortByFlowId) };
      }
      default: {
        return state;
      }
    }
  },
  selectors: {
    allFlows: new Selector(selectors => state => selectors.entities(state).allFlows),
    allFlowsCompareTimePeriod: new Selector(selectors => state => selectors.entities(state).allFlowsCompareTimePeriod),
    compareFlows: new Selector(selectors => state => selectors.entities(state).compareFlows),
    compareTimePeriodFlows: new Selector(selectors => state => selectors.entities(state).compareTimePeriodFlows),
  },
  creators: ({ types }) => ({
    setAllFlows: data => ({ type: types.SET_ALL_FLOWS, data }),
    setAllFlowsCompareTimePeriod: data => ({ type: types.SET_ALL_FLOWS_COMPARE_TIME_PERIOD, data }),
    removeAllFlowsCompareTimePeriod: () => ({ type: types.REMOVE_ALL_FLOWS_COMPARE_TIME_PERIOD }),
    setCompareFlows: data => ({ type: types.SET_COMPARE_FLOW, data }),
    removeCompareFlows: flowIds => ({ type: types.REMOVE_COMPARE_FLOWS, flowIds }),
    setCompareTimePeriodFlows: data => ({ type: types.SET_COMPARE_TIME_PERIOD_FLOWS, data }),
    removeCompareTimePeriodFlows: flowIds => ({ type: types.REMOVE_COMPARE_TIME_PERIOD_FLOWS, flowIds }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
