import Duck from 'extensible-duck';
import genericEntityDuck, { withHooks } from '../../util/genericEntityDuck';
import { getItem } from '../../util/localStorage';
import { defaultLocale } from '../../locales/i18n';
import { datalakeGraphql, datalakeApi } from '../../config/backendRoutes';

const storeKey = 'configuration';
const namespace = 'app';
const defaultThemeColor = 'dark';

export const THEME_KEY = 'UTILIHIVE:THEME';

const duck = withHooks(
  genericEntityDuck(namespace, storeKey, 'id', namespace).extend({
    types: ['SET_DARK_MODE'],
    initialState: {
      data: {
        organization: {
          modules: [],
          dataLakeApiHost: datalakeApi,
          dataLakeGraphqlHost: datalakeGraphql,
        },
      },
    },
    selectors: {
      userConfiguration: new Duck.Selector(selectors => state => selectors.data(state).user || {}),
      organizationConfiguration: new Duck.Selector(selectors => state => selectors.data(state).organization || {}),
      role: new Duck.Selector(selectors => state => selectors.data(state).role),
      modules: new Duck.Selector(selectors => state => {
        if (window.env.MODULES) {
          return window.env.MODULES;
        }
        let organizationConfiguration = selectors.organizationConfiguration(state);
        return organizationConfiguration.modules;
      }),
      configuration: new Duck.Selector(selectors => state => {
        const userConfiguration = selectors.userConfiguration(state);
        const organizationConfiguration = selectors.organizationConfiguration(state);
        return {
          locale: userConfiguration.locale || organizationConfiguration.locale || defaultLocale,
          theme: userConfiguration.theme || organizationConfiguration.theme || getItem(THEME_KEY) || defaultThemeColor,
          dataLakeApiHost: organizationConfiguration.dataLakeApiHost,
          dataLakeGraphqlHost: organizationConfiguration.dataLakeGraphqlHost,
          timeZone:
            userConfiguration.timeZone ||
            organizationConfiguration.timeZone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
      }),
    },
  })
);

export { duck as default, storeKey };
export const { creators, reducer, types, selectors, hooks } = duck;
