import Duck from 'extensible-duck';
import genericEntityDuck from '../../util/genericEntityDuck';
import { getJsonItem, setJsonItem } from 'util/localStorage';

const storeKey = 'nav';
const namespace = 'app';

export const SUBMENU_OPEN_KEY = 'UTILIHIVE:submenuOpen';
export const setSubMenuOpenStorage = value => setJsonItem(SUBMENU_OPEN_KEY, value);
export const getSubMenuOpenStorage = () => getJsonItem(SUBMENU_OPEN_KEY);

const duck = genericEntityDuck(namespace, storeKey, 'id', namespace).extend({
  types: ['SET_MENU_OPEN', 'SET_SUBMENU_OPEN'],
  initialState: {
    menuOpen: false,
    subMenuOpen: getSubMenuOpenStorage() !== null ? getSubMenuOpenStorage() : true,
  },
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_MENU_OPEN:
        return {
          ...state,
          menuOpen: action.data,
        };
      case types.SET_SUBMENU_OPEN:
        if (state.subMenuOpen !== action.data) {
          setSubMenuOpenStorage(action.data);
        }
        return {
          ...state,
          subMenuOpen: action.data,
        };
      default: {
        return state;
      }
    }
  },
  selectors: {
    menuOpen: new Duck.Selector(selectors => state => selectors.entities(state).menuOpen),
    subMenuOpen: new Duck.Selector(selectors => state => selectors.entities(state).subMenuOpen),
  },
  creators: ({ types }) => ({
    setMenuOpen: open => ({ type: types.SET_MENU_OPEN, data: open }),
    setSubMenuOpen: open => ({ type: types.SET_SUBMENU_OPEN, data: open }),
  }),
});

export { duck as default, storeKey };
export const { creators, reducer, types, selectors } = duck;
