import PropTypes from 'prop-types';

export const USER_ROLES = {
  ADMIN: 'Admin',
  OPERATOR: 'Operator',
  MONITOR: 'Monitor',
  BILLING: 'Billing',
  DEPLOYER: 'Deployer',
  AGENT: 'Agent',
};

export const OWNER_ROLE = 'Owner';

export const USER_ROLES_LABELS = [
  { intlId: 'UserConfig-option-admin', value: USER_ROLES.ADMIN },
  { intlId: 'UserConfig-option-operator', value: USER_ROLES.OPERATOR },
  { intlId: 'UserConfig-option-monitor', value: USER_ROLES.MONITOR },
  { intlId: 'UserConfig-option-billing', value: USER_ROLES.BILLING },
  { intlId: 'UserConfig-option-deployer', value: USER_ROLES.DEPLOYER },
  { intlId: 'UserConfig-option-agent', value: USER_ROLES.AGENT },
];

export const isUserOwner = user =>
  user &&
  user.roles &&
  user.roles.some(item => (item.organizationId === 1 || item.organizationId === '1') && item.role === USER_ROLES.ADMIN);

export const isUserAdmin = user => organizationId =>
  user &&
  user.roles &&
  user.roles.some(item => item.organizationId === organizationId && item.role === USER_ROLES.ADMIN);

export const isUserSomeAdmin = user => user && user.roles && user.roles.some(item => item.role === USER_ROLES.ADMIN);

export const rolesPropType = PropTypes.oneOf([
  USER_ROLES.ADMIN,
  USER_ROLES.OPERATOR,
  USER_ROLES.MONITOR,
  USER_ROLES.BILLING,
]);

export const userRolePropType = PropTypes.shape({
  role: rolesPropType,
  userId: PropTypes.number,
  organizationId: PropTypes.number,
});

export const userPropType = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  organizationId: PropTypes.number,
  roles: PropTypes.arrayOf(userRolePropType),
});
