import { connect } from 'react-redux';
import { selectors as configurationSelectors } from '../../ducks/configurationDuck';
import { selectors as userSelectors } from '../../ducks/userDuck';
import App from './App';

const mapStateToProps = state => ({
  locale: configurationSelectors.configuration(state).locale,
  user: userSelectors.user(state),
  organizationShortName: userSelectors.organizationShortName(state),
});

const AppContainer = connect(mapStateToProps)(App);
export default AppContainer;
