import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Scene from 'sharedComponents/Scene';
import TitleBar from 'sharedComponents/TitleBar/TitleBar';

const Fallback = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 400);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <Scene noBar noMargin>
      <TitleBar title={''} />
      <Box sx={{ height: '40vh' }}>{show && <LinearProgress />}</Box>
    </Scene>
  );
};

export default Fallback;
