import React from 'react';
import { useDispatch } from 'react-redux';
import { creators as navCreators } from 'App/ducks/navDuck';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';

const AppFallbackUI = () => {
  const dispatch = useDispatch();
  const setMenuOpen = () => dispatch(navCreators.setMenuOpen(true));
  return (
    <Box
      sx={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        pt: 4,
      }}
    >
      <Paper sx={{ p: 4 }}>
        <Typography sx={{ mb: 2 }} variant="h4">
          <FormattedMessage id={'NotFound-AppFallbackUI-title'} defaultMessage={'The page could not be found.'} />
        </Typography>
        <Typography sx={{ mb: 2 }} variant="subtitle1">
          <FormattedMessage
            id={'NotFound-AppFallbackUI-content'}
            defaultMessage={
              "You have nagiated to a path that doesn't exist. Please check the URL or use the menu to nagivate to a different page."
            }
          />
        </Typography>
        <Button onClick={setMenuOpen} variant="contained" color="primary">
          <FormattedMessage id={'NotFound-AppFallbackUI-button'} defaultMessage={'Open menu'} />
        </Button>
      </Paper>
    </Box>
  );
};

export default AppFallbackUI;
