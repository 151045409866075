import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'secrets';
const duck = createFetchDuck('HEARTBEAT', storeKey, 'id', 'heartbeat').extend({
  types: ['CLEAN_DATA_BEFORE_FETCH'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.CLEAN_DATA_BEFORE_FETCH:
        return { ...state, data: [] };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    cleanDataBeforeFetch: () => ({ type: types.CLEAN_DATA_BEFORE_FETCH }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
