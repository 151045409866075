import { connect } from 'react-redux';
import { selectors as authSelectors } from '../../ducks/authDuck';
import Login from './Login';
import { loginAction, activeDirectoryAction } from '../../actions/auth';

const mapStateToProps = state => ({
  error: authSelectors.error(state),
  isLoading: authSelectors.isLoading(state),
  token: authSelectors.token(state),
});

const mapDispatchToProps = {
  login: loginAction,
  activeDirectory: activeDirectoryAction,
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
export default LoginContainer;
