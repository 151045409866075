import { useMemo } from 'react';
import format from 'xml-formatter';

const isParseError = parsedDocument => {
  const parser = new DOMParser();
  const erroneousParse = parser.parseFromString('<', 'text/xml');
  const parserErrorNS = erroneousParse.getElementsByTagName('parsererror')[0].namespaceURI;
  return parsedDocument.getElementsByTagNameNS(parserErrorNS, 'parsererror').length > 0;
};

const parseXml = xmlString => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(xmlString, 'text/xml');
  if (isParseError(dom)) {
    throw new Error('Error parsing XML');
  }

  return dom;
};

export const getParsedValueAndMode = valueToParse => {
  let value;
  let mode;
  try {
    value = JSON.stringify(JSON.parse(valueToParse), null, 2);
    mode = 'application/json';
  } catch (e) {
    try {
      parseXml(valueToParse);
      value = format(valueToParse, { collapseContent: false, lineSeparator: '\n' });
      mode = 'application/xml';
    } catch (e) {
      value = valueToParse;
      mode = 'application/text';
    }
  }

  return { value, mode };
};

export const useParsedValueAndMode = valueToParse => useMemo(() => getParsedValueAndMode(valueToParse), [valueToParse]);
