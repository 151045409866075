import { grey } from '@mui/material/colors';

const surface = {
  '1': '#000000',
  '2': '#1b2a33',
  '3': '#121f28',
  '4': '#162530',
  '5': '#23343f',
  '6': '#0c1419',
  '7': '#05090c',
  '8': '#2c404d',
};

const borders = {
  white: '#ffffff',
  minimal: '#0c1419',
  subtle: '#121f26',
  moderate: '#324e60',
  bold: '#23343f',
  strong: '#4c6472',
  focus: '#77cdfc',
  black: '#121f26',
};

const text = {
  primary: '#ffffff',
  secondary: '#b6c3cc',
  tertiary: '#8492a6',
  interaction: '#77cdfc',
  destructive: '#f34336',
  reverse: '#121f26',
  disabled: '#6B727C',
  labelwhite: '#ffffff',
  labelblack: '#121F26',
};

const icons = {
  default: '#eff4f6',
  hover: '#77cdfc',
  disabled: '#6B727C',
  destructive: '#f34336',
};

const feedback = {
  information: '#007aac',
  danger: '#b40000',
  serious: '#ff8b3a',
  warning: '#fec602',
  success: '#7faf1c',
  neutral: '#d8e0e5',
  neutralAlt: '#e5f5ff',
  danger2: '#ed5120',
  warning2: '#ffe68b',
};

const workflow = {
  new: '#007acc',
  draft: '#4d4d4d',
  submitted: '#4056ab',
  reviewing: '#6b4d9c',
  evaluating: '#90467b',
  inProgress: '#955c10',
  complete: '#2e546d',
  approved: '#42632a',
  rejected: '#912a49',
};

const dataWiz = {
  '1': '#82D2FB',
  '2': '#5ABFF8',
  '3': '#3F92CB',
  '4': '#99BAD0',
  '5': '#3E7091',
  '6': '#315C7E',
  '7': '#DE5558',
  '8': '#82BDB1',
  '9': '#5DAC9F',
  '10': '#3F8678',
  '11': '#31675C',
  '12': '#9DA7FF',
  '13': '#6D81FF',
  '14': '#4F6AFF',
  '15': '#4156D1',
  '16': '#EBB275',
  '17': '#D97A14',
  '18': '#B66612',
  '19': '#DBD048',
  '20': '#B4AA23',
  '21': '#E496BD',
  '22': '#902B49',
  '23': '#7A7A7A',
  '24': '#CCB3F5',
  '25': '#A67BEC',
  '26': '#A5D671',
  '27': '#7DBB22',
  '28': '#507A13',
};

const paletteDark = {
  mode: 'dark',
  primary: { main: '#007aac' },
  secondary: { main: '#217554' },
  success: { main: feedback.success },
  warning: { main: feedback.warning },
  error: { main: '#f34336', dark: feedback.danger },
  info: { main: feedback.information },
  borders,
  surface,
  icons,
  workflow,
  dataWiz,
  feedback,
  text,
  background: {
    paper: surface['3'],
    default: surface['3'],
    contrast: grey[900],
    primary: surface['5'],
  },
  alternateTable: {
    main: '#3a3a3a',
  },
};

export default paletteDark;
