import Duck from 'extensible-duck';
import genericEntityDuck from '../../util/genericEntityDuck';

const storeKey = 'organization';
const namespace = 'app';

const duck = genericEntityDuck(namespace, storeKey, 'id', namespace).extend({
  selectors: {
    organization: new Duck.Selector(selectors => state => selectors.data(state)),
  },
});

export { duck as default, storeKey };
export const { creators, reducer, types, selectors } = duck;
