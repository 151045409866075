import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const SceneFallbackUI = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        pt: 4,
      }}
    >
      <Paper
        sx={{
          p: 4,
        }}
      >
        <Typography sx={{ mb: 2 }} variant="h4">
          <FormattedMessage id={'NotFound-SceneFallbackUI-title'} defaultMessage={'The page could not be found.'} />
        </Typography>
        <Typography sx={{ mb: 2 }} variant="subtitle1">
          <FormattedMessage
            id={'NotFound-SceneFallbackUI-content'}
            defaultMessage={
              'The page you are looking for does not exist. Please check the URL or use the menu to nagivate to a different page.'
            }
          />
        </Typography>
      </Paper>
    </Box>
  );
};

export default SceneFallbackUI;
