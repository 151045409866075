import Duck from 'extensible-duck';
import { baseDuck } from '../../util/genericEntityDuck';
import { convertLatLngKeysToLatitudeLongitudeKeys } from '../scenes/Ecosystem/EventScenarioMap/mapUtils';
import { types as eventScenarioTypes } from './eventScenarioDuck';
import { isEventValid, isEventScenarioValid } from '../domain/eventScenario';

const storeKey = 'newEventScenario';
const namespace = 'ghostwriter';
const duck = baseDuck().extend({
  namespace,
  store: storeKey,
  types: [
    'TOGGLE_OPEN',
    'SET_NAME',
    'SET_TYPE',
    'SET_TIME_START',
    'SET_TIME_END',
    'SET_POLYGON_AREA',
    'DELETE_INPUT_POLYGON_AREA',
    'ADD_EVENT',
    'START_CREATE_EVENT_SCENARIO',
    'SUCCESS_CREATE_EVENT_SCENARIO',
    'FAIL_CREATE_EVENT_SCENARIO',
    'SET_MAP_BOUNDS',
    'DELETE_POLYGON_AREA',
  ],
  initialState: {
    isOpen: false,
    mapBounds: undefined,
    name: '',
    type: '',
    timeStart: '12:00',
    timeEnd: '13:00',
    polygonArea: undefined,
    events: [],
  },
  reducer: (state, action, { types, initialState }) => {
    switch (action.type) {
      case types.TOGGLE_OPEN:
        return { ...state, isOpen: !state.isOpen };

      case types.SET_MAP_BOUNDS:
        return { ...state, mapBounds: action.bounds };

      case types.SET_NAME:
        return { ...state, name: action.name };

      case types.SET_TYPE:
        return { ...state, type: action.value };

      case types.SET_TIME_START: {
        return { ...state, timeStart: action.value };
      }

      case types.SET_TIME_END:
        return { ...state, timeEnd: action.value };

      case types.SET_POLYGON_AREA:
        return { ...state, polygonArea: action.polygonArea };

      case types.DELETE_INPUT_POLYGON_AREA:
        return { ...state, polygonArea: undefined };

      case types.ADD_EVENT: {
        const newEvent = {
          type: state.type,
          timeStart: state.timeStart,
          timeEnd: state.timeEnd,
          polygonArea:
            state.polygonArea &&
            state.polygonArea.length &&
            state.polygonArea.map(convertLatLngKeysToLatitudeLongitudeKeys),
        };
        return {
          ...state,
          type: '',
          timeStart: initialState.timeStart,
          timeEnd: initialState.timeEnd,
          polygonArea: undefined,
          events: [...state.events, newEvent],
        };
      }

      case eventScenarioTypes.CREATE_SUCCESS:
        return { ...initialState };

      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    toggleOpen: () => ({ type: types.TOGGLE_OPEN }),
    setName: name => ({ type: types.SET_NAME, name }),
    setType: type => ({ type: types.SET_TYPE, value: type }),
    setStartTime: hourString => ({ type: types.SET_TIME_START, value: hourString }),
    setEndTime: hourString => ({ type: types.SET_TIME_END, value: hourString }),
    addEvent: () => ({ type: types.ADD_EVENT }),
    deletePolygonArea: () => ({ type: types.DELETE_POLYGON_AREA }),
    setPolygonArea: polygonArea => ({ type: types.SET_POLYGON_AREA, polygonArea }),
    setBounds: bounds => ({ type: types.SET_MAP_BOUNDS, bounds }),
  }),
  selectors: {
    root: new Duck.Selector(selectors => state => state[namespace][storeKey]),
    name: new Duck.Selector(selectors => state => selectors.root(state).name),
    isOpen: new Duck.Selector(selectors => state => selectors.root(state).isOpen),
    timeStart: new Duck.Selector(selectors => state => selectors.root(state).timeStart),
    timeEnd: new Duck.Selector(selectors => state => selectors.root(state).timeEnd),
    type: new Duck.Selector(selectors => state => selectors.root(state).type),
    events: new Duck.Selector(selectors => state => selectors.root(state).events),
    mapBounds: new Duck.Selector(selectors => state => selectors.root(state).mapBounds),
    polygonArea: new Duck.Selector(selectors => state => selectors.root(state).polygonArea),
    isEventValid: new Duck.Selector(selectors => state => {
      const event = selectors.root(state);
      return isEventValid(event);
    }),
    isEventScenarioValid: new Duck.Selector(selectors => state => {
      const event = selectors.root(state);
      return isEventScenarioValid(event);
    }),
  },
});
export { duck as default, storeKey };
export const { creators, reducer, types, selectors } = duck;
