import { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

export const useOrganizationShortNameHandler = organizationShortNameState => {
  const [organizationShortNameParam, setOrganizationShortNameParam] = useQueryParam('organization', StringParam);
  useEffect(() => {
    if (!!organizationShortNameState && organizationShortNameState !== organizationShortNameParam) {
      setOrganizationShortNameParam(organizationShortNameState, 'replaceIn');
    }
  }, [organizationShortNameState, organizationShortNameParam, setOrganizationShortNameParam]);
};
