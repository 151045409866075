import { Selector } from 'extensible-duck';
import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'deployedFlows';

const duck = createFetchDuck('HEARTBEAT', storeKey, 'id', 'heartbeat').extend({
  types: ['SET_DISABLEMENT', 'SET_UPDATED_FLOW_SPEC'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_DISABLEMENT:
        return {
          ...state,
          data: state.data.map(item => (item.flowSpec.id === action.id ? { ...item, disablement: action.data } : item)),
        };
      case types.SET_UPDATED_FLOW_SPEC:
        return {
          ...state,
          data: state.data.map(item =>
            item.flowSpec.id === action.id ? { ...item, flowSpec: action.updatedFlowSpec } : item
          ),
        };
      default:
        return state;
    }
  },
  selectors: {
    getFlowsByGroup: new Selector(selectors => (state, group) =>
      selectors.data(state).filter(flow => flow.flowSpec.groups.includes(group))
    ),
    getFlowById: new Selector(selectors => (state, id) => selectors.data(state).find(flow => flow.flowSpec.id === id)),
    getGroups: new Selector(selectors => state =>
      Array.from(
        selectors.data(state).reduce((acc, currentItem) => {
          currentItem.flowSpec.groups.forEach(group => acc.add(group));
          return acc;
        }, new Set())
      )
    ),
  },
  creators: ({ types }) => ({
    setDisablement: (flowId, data) => ({ type: types.SET_DISABLEMENT, id: flowId, data }),
    setUpdatedFlow: (flowId, updatedFlowSpec) => ({ type: types.SET_UPDATED_FLOW_SPEC, id: flowId, updatedFlowSpec }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
