import React from 'react';
import Box from '@mui/material/Box';
import { Controller } from 'react-hook-form';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CustomDateInputField from './components/CustomDateInputField';
import CustomTimeInputField from './components/CustomTimeInputField';
import { FormattedMessage } from 'react-intl';

const StartingDate = ({ fromDate, toDate, control }) => (
  <Box sx={{ p: 1 }}>
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink htmlFor="starting-date">
        <FormattedMessage id="DateRangePicker-startingDate-label" defaultMessage="Starting Date" />
      </InputLabel>
      <Controller
        render={({ field }) => (
          <CustomDateInputField
            {...field}
            maxDate={toDate}
            size="small"
            id="starting-date"
            format="YYYY-MM-DD"
            value={fromDate}
            onChange={momentFrom => {
              if (momentFrom && momentFrom.isValid()) {
                field.onChange(momentFrom);
              }
            }}
          />
        )}
        name={'fromDate'}
        control={control}
      />
    </FormControl>
    <Box sx={{ mt: 1 }}>
      <Controller
        render={({ field }) => (
          <DateCalendar
            {...field}
            maxDate={toDate}
            value={fromDate}
            onChange={momentFrom => {
              if (momentFrom && momentFrom.isValid()) {
                field.onChange(momentFrom);
              }
            }}
            sx={{ backgroundColor: 'surface.2', borderRadius: '5px', width: '277px', height: '325px' }}
          />
        )}
        name={'fromDate'}
        control={control}
      />
    </Box>
    <FormControl variant="standard" fullWidth sx={{ mt: 1 }}>
      <InputLabel shrink htmlFor="starting-time">
        Starting Time
      </InputLabel>
      <Controller
        render={({ field }) => (
          <CustomTimeInputField
            {...field}
            value={fromDate}
            maxTime={toDate}
            ampm={false}
            size="small"
            id="starting-time"
            onChange={momentFrom => {
              if (momentFrom && momentFrom.isValid()) {
                field.onChange(momentFrom);
              }
            }}
          />
        )}
        name={'fromDate'}
        control={control}
      />
    </FormControl>
  </Box>
);

export default StartingDate;
