const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'JWT_MIDDLEWARE:authToken';

export const getLocalStorageToken = () => {
  try {
    const jsonString = window.localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
};

export const setLocalStorageToken = token => {
  const jsonString = JSON.stringify(token);
  window.localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, jsonString);
};

export const deleteLocalStorageToken = () => window.localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
