import { useMemo } from 'react';
import { defineMessage } from 'react-intl';
import PersonIcon from '@mui/icons-material/Person';
import AuditIcon from '@mui/icons-material/Policy';
import SettingsIcon from '@mui/icons-material/Settings';
import LockIcon from '@mui/icons-material/Lock';
import OrganizationsIcon from 'utilihive-icons/Organization';
import PersonSettingsIcon from 'utilihive-icons/Settings';
import FeatureToggleIcon from '@mui/icons-material/ToggleOnTwoTone';

import { USER_ROLES, OWNER_ROLE } from 'App/domain/user';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';

export const MODULE_PATH = 'settings';

export const OVERVIEW = '';
export const ADMIN_ROOT_ORGANIZATION_LIST = 'organizations';
export const ADMIN_ORGANIZATION_DETAILS = `${ADMIN_ROOT_ORGANIZATION_LIST}/:organizationId`;
export const ADMIN_ORGANIZATION_USER_DETAILS = `${ADMIN_ORGANIZATION_DETAILS}/user/:userId`;
export const USER_CONFIGURATION = `user`;
export const AUDIT_LOG_ORGANIZATIONS = 'audit';
export const AUDIT_LOG_ORGANIZATIONS_DETAILS = 'audit/:organizationId';
export const CONNECTIVITY_CHECK = 'connectivity';
export const FEATURE_CONFIGURATION = 'feature';

export const children = [
  {
    path: OVERVIEW,
    index: true,
    lazy: () => import(/* webpackChunkName: "Settings:Overview" */ '../scenes/Overview'),
    intlMessage: defineMessage({ id: 'route.settings.overview', defaultMessage: 'Overview' }),
    Icon: PersonIcon,
    restrictRoleAccess: USER_ROLES.ADMIN,
  },
  {
    path: ADMIN_ORGANIZATION_USER_DETAILS,
    lazy: () => import(/* webpackChunkName: "Settings:User" */ '../scenes/User'),
  },
  {
    path: `${ADMIN_ORGANIZATION_DETAILS}/*`,
    lazy: () => import(/* webpackChunkName: "Settings:Organization" */ '../scenes/Organization'),
    intlMessage: defineMessage({ id: 'route.settings.organizations', defaultMessage: 'Organization' }),
    Icon: OrganizationsIcon,
    restrictRoleAccess: USER_ROLES.ADMIN,
  },
  {
    path: ADMIN_ROOT_ORGANIZATION_LIST,
    lazy: () => import(/* webpackChunkName: "Settings:Organizations" */ '../scenes/Organizations'),
  },
  {
    path: USER_CONFIGURATION,
    lazy: () => import(/* webpackChunkName: "Settings:UserConfiguration" */ '../scenes/UserConfiguration'),
    intlMessage: defineMessage({ id: 'route.settings.userConfiguration', defaultMessage: 'User configuration' }),
    Icon: SettingsIcon,
  },
  {
    path: AUDIT_LOG_ORGANIZATIONS,
    lazy: () => import(/* webpackChunkName: "Settings:AuditLogOrganizations" */ '../scenes/AuditLogOrganizations'),
  },
  {
    path: AUDIT_LOG_ORGANIZATIONS_DETAILS,
    lazy: () =>
      import(/* webpackChunkName: "Settings:AuditLogOrganizationDetails" */ '../scenes/AuditLogOrganizationDetails'),
    intlMessage: defineMessage({ id: 'route.settings.audit', defaultMessage: 'Audit' }),
    Icon: AuditIcon,
    restrictRoleAccess: USER_ROLES.ADMIN,
  },
  {
    path: CONNECTIVITY_CHECK,
    lazy: () => import(/* webpackChunkName: "Settings:ConnectivityCheck" */ '../scenes/ConnectivityCheck'),
    intlMessage: defineMessage({ id: 'route.settings.connectivityCheck', defaultMessage: 'Connectivity check' }),
    Icon: LockIcon,
  },
  {
    path: FEATURE_CONFIGURATION,
    lazy: () => import(/* webpackChunkName: "Settings:FeatureConfiguration" */ '../scenes/FeatureConfiguration'),
    intlMessage: defineMessage({ id: 'route.settings.featureConfiguration', defaultMessage: 'Feature configuration' }),
    Icon: FeatureToggleIcon,
    restrictRoleAccess: OWNER_ROLE,
  },
];

const settings = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "Settings" */ 'Settings'),
  intlMessage: defineMessage({ id: 'route.settings', defaultMessage: 'Settings' }),
  Icon: <PersonSettingsIcon />,
  restrictRoleAccess: USER_ROLES.ADMIN,
  children,
};

export const useRoutes = (activeRole, isAdmin, isOwner) => {
  const showOrganizationsList = isOwner && isAdmin;
  const isFoundation = useFeatureToggle(FEATURE_TOGGLES.FOUNDATION);
  return useMemo(
    () => ({
      intlMessage: settings.intlMessage,
      root: settings.path,
      Icon: settings.Icon,
      routes: settings.children
        .filter(child => !!child.intlMessage)
        .filter(({ path }) => (isFoundation && path === `${ADMIN_ORGANIZATION_DETAILS}/*` ? false : true))
        .map(({ intlMessage, Icon, restrictRoleAccess, path, index }) => ({
          intlMessage,
          Icon,
          restrictRoleAccess,
          index,
          path: showOrganizationsList
            ? path.replace('/:organizationId', '')
            : path.replace(':organizationId', activeRole && activeRole.organizationId),
        })),
    }),
    [activeRole, showOrganizationsList, isFoundation]
  );
};

export default settings;
