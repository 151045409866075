import Duck from 'extensible-duck';
import { getJsonItem, setJsonItem } from 'util/localStorage';

const storeKey = 'multiscreen';
const namespace = 'app';

export const MULTISCREEN_KEY = 'UTILIHIVE:multiScreen';

export const setMultiScreenStorage = value => setJsonItem(MULTISCREEN_KEY, value);

export const getMultiScreenStorage = () => getJsonItem(MULTISCREEN_KEY);

const initialState = {
  monitors: 1,
  isMultiScreen: getMultiScreenStorage() || false,
};

const duck = new Duck({
  namespace,
  store: storeKey,
  types: ['SET_MONITORS', 'IS_MULTI_SCREEN'],
  initialState,
  reducer: (state = initialState, action, { types }) => {
    switch (action.type) {
      case types.SET_MONITORS:
        return { ...state, monitors: action.count };
      case types.IS_MULTI_SCREEN: {
        if (state.isMultiScreen !== action.isMultiScreen) {
          setMultiScreenStorage(action.isMultiScreen);
        }
        return { ...state, isMultiScreen: action.isMultiScreen };
      }
      default:
        return state;
    }
  },
  selectors: {
    root: state => state[namespace][storeKey],
    monitors: new Duck.Selector(selectors => state => selectors.root(state).monitors),
    isMultiScreen: new Duck.Selector(selectors => state => selectors.root(state).isMultiScreen),
  },
  creators: ({ types }) => ({
    setMonitors: count => ({ type: types.SET_MONITORS, count }),
    setIsMultiScreen: isMultiScreen => ({ type: types.IS_MULTI_SCREEN, isMultiScreen }),
  }),
});

export { duck as default, storeKey, namespace };
export const { creators, reducer, types, selectors } = duck;
