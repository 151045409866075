import { useMemo } from 'react';
import { defineMessage } from 'react-intl';
import PlatformIcon from '@mui/icons-material/Dns';
import StatusIcon from '@mui/icons-material/DynamicFeed';

import MODULE_NAMES from 'App/config/moduleNames';

export const MODULE_PATH = 'platform';

export const STATUS = '';

const children = [
  {
    path: STATUS,
    index: true,
    lazy: () => import(/* webpackChunkName: "Platform:Status" */ '../scenes/Status'),
    intlMessage: defineMessage({ id: 'route.platform.status', defaultMessage: 'Status' }),
    Icon: StatusIcon,
  },
];

const platform = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "Platform" */ 'Platform'),
  intlMessage: defineMessage({ id: 'route.platform', defaultMessage: 'Platform' }),
  Icon: <PlatformIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.PLATFORM,
  children,
};

export const useRoutes = () => {
  return useMemo(
    () => ({
      intlMessage: platform.intlMessage,
      root: platform.path,
      Icon: platform.Icon,
      routes: platform.children
        .filter(child => !!child.intlMessage)
        .map(({ intlMessage, Icon, path, index }) => ({
          intlMessage,
          Icon,
          path,
          index,
        })),
    }),
    []
  );
};

export default platform;
