import PropTypes from 'prop-types';

export const isEcosystemValid = ecosystem =>
  ecosystem && typeof ecosystem.name === 'string' && ecosystem.name.length > 0;

export const ecosystemShape = PropTypes.shape({
  ecosystemId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  lock: PropTypes.bool.isRequired,
  usagePointCount: PropTypes.number.isRequired,
});
