import { combineReducers } from 'redux';
import { storeKey as authKey, reducer as authReducer } from './authDuck';
import { storeKey as organizationKey, reducer as organizationsReducer } from './organizationDuck';
import { storeKey as userKey, reducer as userReducer } from './userDuck';
import { storeKey as configurationKey, reducer as configurationReducer } from './configurationDuck';
import { storeKey as navKey, reducer as navReducer } from './navDuck';
import { storeKey as featureKey, reducer as featureReducer } from './featureDuck';
import { storeKey as multiScreenKey, reducer as multiScreenReducer } from './multiScreenDuck';
import { storeKey as searchKey, reducer as searchReducer } from './searchDuck';

export default combineReducers({
  [authKey]: authReducer,
  [organizationKey]: organizationsReducer,
  [userKey]: userReducer,
  [configurationKey]: configurationReducer,
  [navKey]: navReducer,
  [featureKey]: featureReducer,
  [multiScreenKey]: multiScreenReducer,
  [searchKey]: searchReducer,
});
