import createFetchDuck from '../../util/genericEntityDuck';
import { Selector } from 'extensible-duck';

export const storeKey = 'resource';
const duck = createFetchDuck('HEARTBEAT', storeKey, 'id', 'heartbeat').extend({
  types: ['SET_REVISIONS', 'SET_PREVIOUS_REVISION'],
  initialState: {
    data: {},
    previousVersion: {},
    revisions: [],
    latestRevision: 0,
  },
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_REVISIONS:
        return { ...state, revisions: [...Array(action.latestRevision + 1).keys()].slice(1) };
      case types.SET_PREVIOUS_REVISION:
        return { ...state, previousVersion: action.previousRevision };
      default:
        return state;
    }
  },
  selectors: {
    revisions: new Selector(selectors => state => selectors.entities(state).revisions),
    previousVersion: new Selector(selectors => state => selectors.entities(state).previousVersion),
  },
  creators: ({ types }) => ({
    setRevisions: latestRevision => ({ type: types.SET_REVISIONS, latestRevision }),
    setPreviousVersion: previousRevision => ({ type: types.SET_PREVIOUS_REVISION, previousRevision }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
