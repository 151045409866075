import { fetchOrganizationAction } from './organization';
import { getConfigurationAction } from './configuration';
import { fetchUserAction } from './user';
import { tokenDuck } from '../../store/jwtMiddleware';

export const initialize = () => async (dispatch, getState) => {
  const token = tokenDuck.selectors.token(getState());
  if (token) {
    await Promise.all([fetchOrganizationAction(token)(dispatch, getState), fetchUserAction(token)(dispatch, getState)]);
    dispatch(getConfigurationAction(token));
  }
};
