import { combineReducers } from 'redux';
import { reducer as apps, storeKey as appsKey } from './apps';
import { reducer as workflows, storeKey as workflowsKey } from './workflows';
import { reducer as workflowsArchive, storeKey as workflowsArchiveKey } from './workflowsArchive';

const datalake = combineReducers({
  [appsKey]: apps,
  [workflowsKey]: workflows,
  [workflowsArchiveKey]: workflowsArchive,
});

export default datalake;
