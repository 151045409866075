import { useSelector, shallowEqual } from 'react-redux';

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
const withHooks = duck => {
  duck.hooks = Object.keys(duck.selectors).reduce((acc, key) => {
    acc[`use${capitalize(key)}Selector`] = () => useSelector(state => duck.selectors[key](state), shallowEqual); // eslint-disable-line
    return acc;
  }, {});
  return duck;
};

export default withHooks;
