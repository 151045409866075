import React, { useCallback } from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReplayIcon from '@mui/icons-material/Replay';
import TodayIcon from '@mui/icons-material/Today';
import { setTimeHistory } from 'App/api/localStorage';
import { useDateContext } from '../FilterBar/FancyDateSelect/utils/DateContext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import moment from 'moment/moment';

export const DATE_RANGE_PICKER_OPTIONS = [
  {
    intl: defineMessage({ id: 'DateRangePicker-option-today', defaultMessage: 'Today' }),
    getDate: () => ({
      fromDate: moment().startOf('day'),
      toDate: moment(),
      dateOptionIndex: 0,
    }),
  },
  {
    intl: defineMessage({ id: 'DateRangePicker-option-yesterday', defaultMessage: 'Yesterday' }),
    getDate: () => ({
      fromDate: moment().subtract(1, 'day'),
      toDate: moment(),
      dateOptionIndex: 1,
    }),
  },
  {
    intl: defineMessage({ id: 'DateRangePicker-option-lastWeek', defaultMessage: 'Last Week' }),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(7, 'days'),
      toDate: moment(),
      dateOptionIndex: 2,
    }),
  },
  {
    intl: defineMessage({ id: 'DateRangePicker-option-lastMonth', defaultMessage: 'Last Month' }),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(30, 'days'),
      toDate: moment(),
      dateOptionIndex: 3,
    }),
  },
  {
    intl: defineMessage({
      id: 'DateRangePicker-option-lastMonths',
      defaultMessage: 'Last {months} Months',
      values: { months: 3 },
    }),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(90, 'days'),
      toDate: moment(),
      dateOptionIndex: 4,
    }),
  },
  {
    intl: defineMessage({
      id: 'DateRangePicker-option-lastMonths',
      defaultMessage: 'Last {months} Months',
      values: { months: 6 },
    }),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(180, 'days'),
      toDate: moment(),
      dateOptionIndex: 5,
    }),
  },
  {
    intl: defineMessage({
      id: 'DateRangePicker-option-lastMonths',
      defaultMessage: 'Last {months} Months',
      values: { months: 12 },
    }),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(1, 'years'),
      toDate: moment(),
      dateOptionIndex: 6,
    }),
  },
];

const QuickDates = ({ handleClose }) => {
  const { updateDates } = useDateContext();
  const handleClick = useCallback(
    dateRange => {
      updateDates(dateRange);
      setTimeHistory(dateRange);
      handleClose();
    },
    [updateDates, handleClose]
  );
  return (
    <Box sx={{ p: 2 }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0.01em',
          textAlign: 'left',
          pb: 0.5,
        }}
        color="text.secondary"
      >
        <FormattedMessage id="DateRangePicker-QuickDates-title" />
      </Typography>
      <List disablePadding>
        {DATE_RANGE_PICKER_OPTIONS.map((item, idx) => (
          <ListItem key={idx} disablePadding>
            <ListItemButton sx={{ height: '32px', p: 1 }} onClick={() => handleClick(item.getDate())}>
              <ListItemIcon sx={{ minWidth: 24, pr: 1 }}>
                {idx === 0 ? <TodayIcon fontSize="small" /> : <ReplayIcon fontSize="small" />}
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage {...item.intl} />}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default QuickDates;
