import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'App';
import { initializeSentry } from 'App/scenes/App/Trackers';

initializeSentry();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
