import { lighten } from '@mui/material/styles';
import { amber, blue } from '@mui/material/colors';

const palette = {
  mode: 'light',
  primary: { main: '#88c666' },
  secondary: { main: '#217554' },
  warning: {
    main: amber[600],
  },
  info: {
    main: blue[600],
  },
  background: {
    paper: '#fff',
    default: '#fafafa',
    alternate: '#f0f0f0',
    contrast: '#dddddd',
    primary: lighten('#cbe4bc', 0.5),
  },
  primaryGrape: {
    main: '#773999',
    dark: '#341b43',
  },
  alternateTable: {
    main: '#eee',
  },
  graph: {
    'graph-1': '#88c666',
    'graph-2': '#f05d2a',
    'graph-3': '#ffde00',
    main: [
      '#7BDCB5',
      '#00D084',
      '#8ED1FC',
      '#0693E3',
      '#FF6900',
      '#FCB900',
      '#ABB8C3',
      '#EB144C',
      '#F78DA7',
      '#9900EF',
    ],
    dark: [
      '#578572',
      '#00915c',
      '#6392b0',
      '#04669e',
      '#b24900',
      '#b08100',
      '#778088',
      '#a40e35',
      '#ac6274',
      '#6b00a7',
    ],
  },
};

export default palette;
