import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'flowAccesses';
const duck = createFetchDuck('HEARTBEAT', storeKey, 'flowAccessId', 'heartbeat').extend({
  initialState: (_, initialState) => ({
    ...initialState,
    data: [],
  }),
  types: ['CLEAR_DATA'],
  reducer: (state, action, { initialState, types }) => {
    switch (action.type) {
      case types.CLEAR_DATA: {
        return {
          ...state,
          data: [],
        };
      }
      default:
        return state;
    }
  },
  selectors: {},
  creators: ({ types }) => ({
    clearData: () => ({ type: types.CLEAR_DATA }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
