import { creators as userCreators } from '../ducks/userDuck';
import { creators as organizationCreators } from '../ducks/organizationDuck';
import { creators as featuresCreators } from '../ducks/featureDuck';
import { tokenDuck } from '../../store/jwtMiddleware';
import { getConfigurationAction } from './configuration';

export const mapRolesAndOrganizations = profile => {
  let roleAndOrg = [];
  const grantKeys = Object.keys(profile['urn:zitadel:iam:org:project:roles'][1]);
  grantKeys.forEach(grantKey => {
    const role = grantKey.split(':').pop();
    const organizationIds = Object.keys(profile['urn:zitadel:iam:org:project:roles'][0][grantKey]);
    organizationIds.forEach(ofg => {
      roleAndOrg.push({
        role: role,
        organizationId: ofg,
        organizationShortName: profile['urn:zitadel:iam:org:project:roles'][0][grantKey][ofg].split('.')[0],
      });
    });
  });
  return roleAndOrg;
};

const getRoles = profile => {
  const rolesAndOrganizations = mapRolesAndOrganizations(profile);
  return rolesAndOrganizations.map(rog => ({
    role: rog.role,
    userId: profile.sub,
    organizationId: rog.organizationId,
    organizationDetails: {
      name: rog.organizationShortName,
      shortName: rog.organizationShortName,
    },
  }));
};

const mapUser = ({ profile }) => {
  const roles = getRoles(profile);
  return {
    userId: profile.sub,
    email: profile.email,
    name: profile.name,
    organizationId: roles[0].organizationId,
    roles: roles,
  };
};

const mapOrganization = user => {
  return {
    organizationId: user.roles[0].organizationId,
    organizationName: user.roles[0].organizationDetails.name,
    organizationShortName: user.roles[0].organizationDetails.shortName,
    managementApiEnabled: true,
  };
};

export const initializeFoundation = userInfo => async (dispatch, getState) => {
  const user = mapUser(userInfo);
  const organization = mapOrganization(user);
  await dispatch(getConfigurationAction());
  dispatch(userCreators.fetchSuccess(user));
  dispatch(organizationCreators.fetchSuccess(organization));
  dispatch(featuresCreators.fetchSuccess({}));
  dispatch(
    tokenDuck.creators.setToken(userInfo.access_token, 'refreshToken', new Date(userInfo.expires_at * 1000).toString())
  );
};
