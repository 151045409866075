import Duck from 'extensible-duck';
import createFetchDuck from '../../util/genericEntityDuck';
import { types as usagePointsTypes } from './usagePointsDuck';
import { types as usagePointsUploadTypes } from './usagePointsUploadDuck';

export const storeKey = 'ecosystems';

const duck = createFetchDuck('ghostwriter', storeKey, 'ecosystemId', 'ghostwriter').extend({
  types: ['SET_ECOSYSTEM_ID', 'TOGGLE_CREATING'],
  initialState: (_, initialState) => ({
    ...initialState,
    ecosystemId: undefined,
    isCreating: false,
  }),
  reducer: (state, action, { initialState, types }) => {
    switch (action.type) {
      case types.SET_ECOSYSTEM_ID:
        return { ...state, ecosystemId: action.ecosystemId };

      case types.DELETE_SUCCESS:
        return state.ecosystemId === action.data.ecosystemId
          ? {
              ...state,
              ecosystemId: undefined,
              ecosystemSelectorIsOpen: state.data.length > 0,
            }
          : state;

      case types.TOGGLE_CREATING:
        return { ...state, isCreating: !state.isCreating };

      case types.CREATE_SUCCESS:
        return { ...state, isCreating: false };

      case usagePointsTypes.CREATE_SUCCESS:
      case usagePointsUploadTypes.CREATE_SUCCESS:
        return {
          ...state,
          data: state.data.map(ecosystem =>
            ecosystem.ecosystemId === action.data.ecosystemId ? { ...ecosystem, lock: true } : ecosystem
          ),
        };

      default:
        return state;
    }
  },
  selectors: {
    isCreating: new Duck.Selector(selectors => state => selectors.entities(state).isCreating),
    ecosystemId: new Duck.Selector(selectors => state => selectors.entities(state).ecosystemId),
    getSelectedEcosystem: new Duck.Selector(selectors => state =>
      selectors.data(state).find(ecosystem => ecosystem.ecosystemId === selectors.ecosystemId(state)) || {}
    ),
    isDemoRunning: new Duck.Selector(selectors => state => Boolean((selectors.getSelectedEcosystem(state) || {}).lock)),
  },
  creators: ({ types }) => ({
    setEcosystemId: ecosystemId => ({ type: types.SET_ECOSYSTEM_ID, ecosystemId }),
    toggleCreating: () => ({ type: types.TOGGLE_CREATING }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
