import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'automationStrategy';
const duck = createFetchDuck('ASSETS', storeKey, 'id', 'assets').extend({
  initialState: {
    data: {},
  },
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
