import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FEATURE_TOGGLES, selectors } from 'App/ducks/featureDuck';

const useFeatureToggle = nameOrFunction => {
  const features = useFeatureToggles();
  return useMemo(() => {
    if (typeof nameOrFunction === 'function') {
      return features[nameOrFunction(FEATURE_TOGGLES)];
    }
    return features[nameOrFunction];
  }, [features, nameOrFunction]);
};

export const useFeatureToggles = () => useSelector(selectors.features);

export default useFeatureToggle;
