import { selectors } from './tokenDuck';
import { getLocalStorageToken, setLocalStorageToken, deleteLocalStorageToken } from './localStorage';

const equalTokens = (token1, token2) =>
  token1.token === token2.token && token1.refreshToken === token2.refreshToken && token1.expires === token2.expires;

export const jwtListener = state => {
  const tokenObject = selectors.tokenObject(state);
  const localStorageToken = getLocalStorageToken() || {};
  if (tokenObject.token && !equalTokens(tokenObject, localStorageToken)) {
    setLocalStorageToken(tokenObject);
  }
  if (localStorageToken.token && !tokenObject.token) {
    deleteLocalStorageToken();
  }
};
