import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { MODULE_PATH } from 'Heartbeat/routes/routes';
import { FormattedMessage } from 'react-intl';
import { PublicPageWrapper } from 'App/presentation/components';

const AppFallbackUI = () => {
  return (
    <PublicPageWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 4,
        }}
      >
        <Paper sx={{ p: 4 }}>
          <Typography sx={{ mb: 2 }} variant="h4">
            <FormattedMessage
              id={'ErrorBoundary-AppFallbackUI-title'}
              defaultMessage={"We're sorry - something's gone wrong."}
            />
          </Typography>
          <Typography sx={{ mb: 2 }} variant="subtitle1">
            <FormattedMessage
              id={'ErrorBoundary-AppFallbackUI-content'}
              defaultMessage={
                'A report has been sent to our team. If the error continues to occur please contact us for help.'
              }
            />
          </Typography>
          <Box
            component="a"
            sx={{
              color: 'primary.main',
            }}
            href={`/${MODULE_PATH}`}
          >
            <FormattedMessage
              id={'ErrorBoundary-AppFallbackUI-button'}
              defaultMessage={'Try going back to Heartbeat'}
            />
          </Box>
        </Paper>
      </Box>
    </PublicPageWrapper>
  );
};

export default AppFallbackUI;
