import React, { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import { MODULE_PRIORITY } from '../../domain/modules';
import { Navigate, useLocation } from 'react-router-dom';
import { selectors as configurationSelectors } from '../../ducks/configurationDuck';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';

import Nav from '../../components/Navigation';
import Footer from '../../components/Footer';
import GENav from '../../components/NavigationBar';
import Container from './Container';
import Toasts from './Toasts';
import Initialize from './Initialize';
import { useStore } from 'react-redux';
import ZitadelProvider from '../../components/ZitadelProvider';

const ModuleRedirectHandler = () => {
  const store = useStore();
  const [doRedirect, setDoRedirect] = useState(false);
  const location = useLocation();
  const modules = useMemo(() => {
    return configurationSelectors.modules(store.getState()) || [];
  }, [store]);
  const module = MODULE_PRIORITY.find(module => modules.includes(module.key));
  useEffect(() => {
    const timeout = setTimeout(() => {
      // Set timeout to prevent redirect loop and let router load
      setDoRedirect(true);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);
  if (doRedirect && location.pathname === '/' && module) {
    return <Navigate to={module.path} replace />;
  } else if (doRedirect && location.pathname === '/') {
    return <Navigate to={'/settings'} replace />;
  } else {
    return null;
  }
};

const AppWrapper = () => {
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ZitadelProvider>
          <Initialize>
            <ModuleRedirectHandler />
            <Container>
              {geThemeFeature ? <GENav /> : <Nav />}
              <Toasts />
              <Box
                component="main"
                sx={{
                  zIndex: 0,
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  width: '100vw',
                  pt: geThemeFeature && 8,
                  pb: geThemeFeature && '40px',
                }}
              >
                <Outlet />
                {geThemeFeature && <Footer />}
              </Box>
            </Container>
          </Initialize>
        </ZitadelProvider>
      </LocalizationProvider>
    </QueryParamProvider>
  );
};

export default AppWrapper;
