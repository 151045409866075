import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'simulations';
const duck = createFetchDuck('ghostwriter', storeKey, undefined, 'ghostwriter').extend({
  types: [],
  initialState: (_, initialState) => ({
    ...initialState,
  }),
  reducer: (state, action, { types }) => {
    switch (action.type) {
      default:
        return state;
    }
  },
  creators: ({ types }) => ({}),
  selectors: {},
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
