import Duck from 'extensible-duck';
import { types as authTypes } from '../../App/ducks/authDuck';

const baseDuck = () =>
  new Duck({
    reducer: (state, action, { initialState }) => {
      switch (action.type) {
        case authTypes.LOGOUT:
          return initialState;

        default:
          return state;
      }
    },
  });

export default baseDuck;
