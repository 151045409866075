import { connect } from 'react-redux';
import { selectors as navSelectors, creators as navCreators } from '../../ducks/navDuck';
import { selectors as userSelectors } from '../../ducks/userDuck';
import { selectors as configurationSelectors } from '../../ducks/configurationDuck';
import Navigation from './Navigation';

const mapStateToProps = state => ({
  modules: configurationSelectors.modules(state) || [],
  menuOpen: navSelectors.menuOpen(state),
  userRoles: userSelectors.roles(state),
});

const mapDispatchToProps = {
  setMenuOpen: navCreators.setMenuOpen,
};

const NavigationContainer = connect(mapStateToProps, mapDispatchToProps)(Navigation);

export default NavigationContainer;
