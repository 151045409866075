import Duck from 'extensible-duck';
import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'search';
const duck = createFetchDuck('app', storeKey, 'id', 'app').extend({
  initialState: {
    data: [],
    showDialog: false,
  },
  types: ['SET_SHOW_DIALOG', 'TOGGLE_DIALOG'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_SHOW_DIALOG:
        return { ...state, showDialog: action.data };
      case types.TOGGLE_DIALOG:
        return { ...state, showDialog: !state.showDialog };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    setShowDialog: data => ({ type: types.SET_SHOW_DIALOG, data }),
    toggleDialog: () => ({ type: types.TOGGLE_DIALOG }),
  }),
  selectors: {
    root: state => state,
    showDialog: new Duck.Selector(selectors => state => selectors.entities(state).showDialog),
  },
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
