import Duck from 'extensible-duck';
import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'tasks';

const initialState = {
  showTasksList: false,
  showTaskId: null,
  data: [],
  loading: false,
  fetchLoading: false,
  createLoading: false,
  updateLoading: false,
  deleteLoading: false,
  initialized: false,
  error: undefined,
  fetchError: undefined,
  createError: undefined,
  updateError: undefined,
  deleteError: undefined,
};

const duck = createFetchDuck('TASKS', storeKey, 'id', storeKey).extend({
  initialState,
  types: ['SET_SHOW_TASKS_LIST', 'SET_SHOW_TASK_ID'],
  reducer: (state = initialState, action, { types }) => {
    switch (action.type) {
      case types.SET_SHOW_TASKS_LIST:
        return { ...state, showTasksList: action.data };
      case types.SET_SHOW_TASK_ID:
        return { ...state, showTaskId: action.data };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    setShowTasksList: data => ({ type: types.SET_SHOW_TASKS_LIST, data }),
    setShowTaskId: data => ({ type: types.SET_SHOW_TASK_ID, data }),
  }),
  selectors: {
    root: state => state,
    showTasksList: new Duck.Selector(selectors => state => selectors.entities(state).showTasksList),
    showTaskId: new Duck.Selector(selectors => state => selectors.entities(state).showTaskId),
    hasNewTasks: new Duck.Selector(selectors => state => selectors.data(state).length > 0),
  },
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
