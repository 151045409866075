import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const Container = ({ children }) => <Box sx={{ display: 'flex' }}>{children}</Box>;

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
