import { combineReducers } from 'redux';
import { reducer as ecosystem, storeKey as ecosystemKey } from './ecosystemDuck';
import { reducer as newEcosystem, storeKey as newEcosystemKey } from './newEcosystemDuck';
import { reducer as simulation, storeKey as simulationKey } from './simulationDuck';
import { reducer as eventScenario, storeKey as eventScenarioKey } from './eventScenarioDuck';
import { reducer as newEventScenario, storeKey as newEventScenarioKey } from './newEventScenarioDuck';
import { reducer as newSimulation, storeKey as newSimulationKey } from './newSimulationDuck';
import { reducer as usagePoints, storeKey as usagePointsKey } from './usagePointsDuck';
import { reducer as usagePointsUpload, storeKey as usagePointsUploadKey } from './usagePointsUploadDuck';
import { reducer as ecosystemConfig, storeKey as ecosystemConfigKey } from './ecosystemConfigDuck';
import { reducer as deliveryProfiles, storeKey as deliveryProfilesKey } from './deliveryProfilesDuck';
import { reducer as deliveryFormats, storeKey as deliveryFormatsKey } from './deliveryFormatsDuck';
import { reducer as batchingPeriods, storeKey as batchingPeriodsKey } from './batchingPeriodsDuck';
import { reducer as simulationsDucks, storeKey as simulationsDucksKey } from './simulationsDucks';

const ghostwriter = combineReducers({
  [ecosystemKey]: ecosystem,
  [newEcosystemKey]: newEcosystem,
  [simulationKey]: simulation,
  [newEventScenarioKey]: newEventScenario,
  [eventScenarioKey]: eventScenario,
  [newSimulationKey]: newSimulation,
  [usagePointsKey]: usagePoints,
  [usagePointsUploadKey]: usagePointsUpload,
  [ecosystemConfigKey]: ecosystemConfig,
  [deliveryProfilesKey]: deliveryProfiles,
  [deliveryFormatsKey]: deliveryFormats,
  [batchingPeriodsKey]: batchingPeriods,
  [simulationsDucksKey]: simulationsDucks,
});

export default ghostwriter;
