import React, { useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useFeatureToggle } from 'sharedComponents/FeatureToggle';
import { getItem, setItem } from 'util/localStorage';
import { FEATURE_TOGGLES } from '../ducks/featureDuck';
import { selectors as configurationSelectors, THEME_KEY } from '../ducks/configurationDuck';
import darkTheme from './darkTheme';
import lightTheme from './lightTheme';
import darkPalette from '../theme/ge/paletteDark';
import lightPalette from '../theme/ge/paletteLight';

import geTheme from './ge/geTheme';

export const useDarkMode = () => {
  const configuration = useSelector(state => configurationSelectors.configuration(state), shallowEqual);
  useEffect(() => {
    const theme = getItem(THEME_KEY);
    if (theme !== configuration.theme) {
      setItem(THEME_KEY, configuration.theme);
    }
  }, [configuration.theme]);
  return configuration.theme !== 'light';
};

const ThemeProvider = ({ children }) => {
  const darkMode = useDarkMode();
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);
  const configuration = useSelector(state => configurationSelectors.configuration(state), shallowEqual);

  const theme = useMemo(() => {
    if (geThemeFeature) {
      const gePalette = configuration.theme === 'light' ? lightPalette : darkPalette;
      return geTheme(gePalette);
    }
    return darkMode ? darkTheme : lightTheme;
  }, [darkMode, geThemeFeature, configuration]);
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
