import createFetchDuck from '../../util/genericEntityDuck';
import { Selector } from 'extensible-duck';

export const storeKey = 'lookupTableRows';
const duck = createFetchDuck('HEARTBEAT', storeKey, 'id', 'heartbeat').extend({
  types: ['SET_LOOK_TABLE_META'],
  initialState: {
    data: [],
    meta: {
      requestedResultMaxSize: undefined,
      requestedOffset: undefined,
      nextOffset: undefined,
      totalNoOfAvailableRows: undefined,
    },
  },
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_LOOK_TABLE_META:
        return { ...state, meta: action.data };
      default:
        return state;
    }
  },
  selectors: {
    meta: new Selector(selectors => state => selectors.entities(state).meta),
  },
  creators: ({ types }) => ({
    setMeta: data => ({ type: types.SET_LOOK_TABLE_META, data }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
