import moment from 'moment';
import PropTypes from 'prop-types';

const geographicPointShape = PropTypes.shape({
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const endDeviceEventType = {
  POWER_FAILED: 'PowerFailed',
  POWER_RESTORED: 'PowerRestored',
};

export const eventConfigurationShape = PropTypes.shape({
  type: PropTypes.string,
  startMinuteOfDay: PropTypes.number,
  durationInMinutes: PropTypes.number,
  polygonArea: PropTypes.arrayOf(geographicPointShape),
  eventScenarioId: PropTypes.number,
  eventConfigurationId: PropTypes.number,
});

export const eventScenarioShape = PropTypes.shape({
  name: PropTypes.string,
  eventConfigurations: PropTypes.arrayOf(eventConfigurationShape).isRequired,
  ecosystemId: PropTypes.number.isRequired,
  eventScenarioId: PropTypes.number,
  configurationsBoundingBox: geographicPointShape,
});

export const isEventValid = event => {
  return (
    typeof event.type === 'string' &&
    event.type.length > 0 &&
    moment.isMoment(event.timeStart) &&
    moment.isMoment(event.timeEnd) &&
    event.timeStart.isBefore(event.timeEnd) &&
    typeof event.polygonArea !== 'undefined'
  );
};

export const isEventScenarioValid = eventScenario => {
  return (
    eventScenario.events &&
    eventScenario.events.length > 0 &&
    eventScenario.events.reduce((res, current) => isEventValid(current) && res, true) &&
    typeof eventScenario.name === 'string' &&
    eventScenario.name.length > 0
  );
};

export const eventScenarioEventTypes = {
  COMMUNICATION_OUT: 'COMMUNICATION_OUT',
  POWER_OUT: 'POWER_OUT',
  METER_FAIL: 'METER_FAIL',
  MISSING_READING_FAIL: 'MISSING_READING_FAIL',
  RCD_SWITCH_DISCONNECT: 'RCD_SWITCH_DISCONNECT',
  GROUND_FAULT: 'GROUND_FAULT',
  VOLTAGE_FAULT: 'VOLTAGE_FAULT',
  CORRUPTED_READ_START: 'CORRUPTED_READ_START',
  POWER_FAIL_AND_MISSING_EVENT_START: 'POWER_FAIL_AND_MISSING_EVENT_START',
  METER_TAMPERING: 'METER_TAMPERING',
  VOLTAGE_IMBALANCE: 'VOLTAGE_IMBALANCE',
  CURRENT_IMBALANCE: 'CURRENT_IMBALANCE',
  SPIKE_READ: 'SPIKE_READ',
  NEGATIVE_READ: 'NEGATIVE_READ',
};
