import { selectors as authSelectors } from '../ducks/authDuck';
import { selectors as userSelectors } from '../ducks/userDuck';
import { creators as configurationCreators } from '../ducks/configurationDuck';
import { fetchConfiguration, putOrganizationConfiguration, putUserConfiguration } from '../api/configurationApi';

export const getConfigurationAction = token => async (dispatch, getState) => {
  const state = getState();
  const stateToken = authSelectors.token(state);
  const activeRole = userSelectors.activeRole(state);
  if (activeRole) {
    try {
      dispatch(configurationCreators.fetch());
      const configuration = await fetchConfiguration(
        token || stateToken,
        activeRole.organizationDetails.shortName,
        activeRole.userId
      );
      dispatch(configurationCreators.fetchSuccess(configuration));
    } catch (e) {
      dispatch(configurationCreators.fetchFailed(e));
    }
  }
};

export const updateOrganizationConfigurationAction = configuration => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const state = getState();
    const token = authSelectors.token(state);
    const activeRole = userSelectors.activeRole(state);
    if (activeRole) {
      try {
        dispatch(configurationCreators.update());
        await putOrganizationConfiguration(token, activeRole.organizationDetails.shortName, configuration);
        await dispatch(getConfigurationAction());
        resolve();
        dispatch(configurationCreators.updateSuccess());
      } catch (e) {
        dispatch(configurationCreators.updateFailed(e));
        reject(e);
      }
    }
  });

export const updateUserConfigurationAction = configuration => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const state = getState();
    const token = authSelectors.token(state);
    const activeRole = userSelectors.activeRole(state);
    if (activeRole) {
      try {
        dispatch(configurationCreators.update());
        await putUserConfiguration(token, activeRole.organizationDetails.shortName, activeRole.userId, configuration);
        await dispatch(getConfigurationAction());
        resolve();
        dispatch(configurationCreators.updateSuccess());
      } catch (e) {
        dispatch(configurationCreators.updateFailed(e));
        reject(e);
      }
    }
  });
