import { combineReducers } from 'redux';

import { reducer as actionsReducer, storeKey as actionsKey } from './actions';
import { reducer as assetsReducer, storeKey as assetsKey } from './assets';
import { reducer as assetReducer, storeKey as assetKey } from './asset';
import { reducer as auditLogReducer, storeKey as auditLogKey } from './auditLog';
import { reducer as automationStrategyReducer, storeKey as automationStrategyKey } from './automationStrategy';
import { reducer as organizationUsersReducer, storeKey as organizationUsersKey } from './organizationUsersDuck';

const assets = combineReducers({
  [actionsKey]: actionsReducer,
  [assetsKey]: assetsReducer,
  [assetKey]: assetReducer,
  [auditLogKey]: auditLogReducer,
  [automationStrategyKey]: automationStrategyReducer,
  [organizationUsersKey]: organizationUsersReducer,
});

export default assets;
