import Duck from 'extensible-duck';
import baseDuck from './baseDuck';

const initialState = {
  data: [],
  loading: false,
  fetchLoading: false,
  createLoading: false,
  updateLoading: false,
  deleteLoading: false,
  initialized: false,
  error: undefined,
  fetchError: undefined,
  createError: undefined,
  updateError: undefined,
  deleteError: undefined,
};

const genericEntityDuck = (namespace, store, idKey = 'id', storeKey = 'entities') =>
  baseDuck().extend({
    namespace,
    store,
    types: [
      'FETCH_START',
      'FETCH_SUCCESS',
      'FETCH_FAILED',
      'LOAD_MORE_START',
      'LOAD_MORE_SUCCESS',
      'LOAD_MORE_FAILED',
      'CREATE_START',
      'CREATE_SUCCESS',
      'CREATE_FAILED',
      'UPDATE_START',
      'UPDATE_SUCCESS',
      'UPDATE_FAILED',
      'DELETE_START',
      'DELETE_SUCCESS',
      'DELETE_FAILED',
    ],
    initialState,
    reducer: (state = initialState, action, { types }) => {
      const error = action.error instanceof Error ? action.error.message : action.error;
      switch (action.type) {
        case types.FETCH_START:
        case types.LOAD_MORE_START:
          return { ...state, loading: true, fetchLoading: true };
        case types.CREATE_START:
          return { ...state, loading: true, createLoading: true };
        case types.UPDATE_START:
          return { ...state, loading: true, updateLoading: true };
        case types.DELETE_START:
          return { ...state, loading: true, deleteLoading: true };

        case types.FETCH_SUCCESS:
          return {
            ...state,
            data: action.data || state.data,
            initialized: true,
            loading: state.createLoading || state.updateLoading || state.deleteLoading,
            fetchLoading: false,
            error: undefined,
            fetchError: undefined,
          };

        case types.LOAD_MORE_SUCCESS:
          return {
            ...state,
            data: [...state.data, ...action.data],
            initialized: true,
            loading: state.createLoading || state.updateLoading || state.deleteLoading,
            fetchLoading: false,
            error: undefined,
            fetchError: undefined,
          };

        case types.CREATE_SUCCESS:
          return {
            ...state,
            data:
              typeof action.data === 'object' && state.data.find(data => data[idKey] === action.data[idKey])
                ? state.data.map(data => (data[idKey] === action.data[idKey] ? action.data : data))
                : [...state.data, action.data],
            loading: state.fetchLoading || state.updateLoading || state.deleteLoading,
            createLoading: false,
            error: undefined,
            createError: undefined,
          };

        case types.UPDATE_SUCCESS:
          return {
            ...state,
            data: state.data.map(data => (data[idKey] === action.data[idKey] ? action.data : data)),
            loading: state.createLoading || state.createLoading || state.deleteLoading,
            updateLoading: false,
            error: undefined,
            updateError: undefined,
          };

        case types.DELETE_SUCCESS:
          return {
            ...state,
            data: state.data.filter(data => data[idKey] !== action.data[idKey]),
            loading: state.createLoading || state.updateLoading || state.updateLoading,
            deleteLoading: false,
            error: undefined,
            deleteError: undefined,
          };

        case types.FETCH_FAILED:
        case types.LOAD_MORE_FAILED:
          return {
            ...state,
            loading: false,
            fetchLoading: false,
            error,
            fetchError: error,
          };

        case types.CREATE_FAILED:
          return {
            ...state,
            loading: false,
            createLoading: false,
            error,
            createError: error,
          };

        case types.UPDATE_FAILED:
          return {
            ...state,
            loading: false,
            updateLoading: false,
            error,
            updateError: error,
          };

        case types.DELETE_FAILED:
          return {
            ...state,
            loading: false,
            deleteLoading: false,
            error,
            deleteError: error,
          };

        default:
          return state;
      }
    },
    selectors: {
      root: state => state,
      entities: new Duck.Selector(selectors => state => selectors.root(state)[storeKey][store]),
      loading: new Duck.Selector(selectors => state => selectors.entities(state).loading),
      fetchLoading: new Duck.Selector(selectors => state => selectors.entities(state).fetchLoading),
      createLoading: new Duck.Selector(selectors => state => selectors.entities(state).createLoading),
      updateLoading: new Duck.Selector(selectors => state => selectors.entities(state).updateLoading),
      deleteLoading: new Duck.Selector(selectors => state => selectors.entities(state).deleteLoading),
      data: new Duck.Selector(selectors => state => selectors.entities(state).data),
      error: new Duck.Selector(selectors => state => selectors.entities(state).error),
      fetchError: new Duck.Selector(selectors => state => selectors.entities(state).fetchError),
      createError: new Duck.Selector(selectors => state => selectors.entities(state).createError),
      updateError: new Duck.Selector(selectors => state => selectors.entities(state).updateError),
      deleteError: new Duck.Selector(selectors => state => selectors.entities(state).deleteError),
      initialized: new Duck.Selector(selectors => state => selectors.entities(state).initialized),
    },
    creators: ({ types }) => ({
      fetch: data => ({ type: types.FETCH_START, data }),
      fetchSuccess: data => ({ type: types.FETCH_SUCCESS, data }),
      fetchFailed: error => ({ type: types.FETCH_FAILED, error }),
      loadMore: data => ({ type: types.LOAD_MORE_START, data }),
      loadMoreSuccess: data => ({ type: types.LOAD_MORE_SUCCESS, data }),
      loadMoreFailed: error => ({ type: types.LOAD_MORE_FAILED, error }),
      create: data => ({ type: types.CREATE_START, data }),
      createSuccess: data => ({ type: types.CREATE_SUCCESS, data }),
      createFailed: error => ({ type: types.CREATE_FAILED, error }),
      update: data => ({ type: types.UPDATE_START, data }),
      updateSuccess: data => ({ type: types.UPDATE_SUCCESS, data }),
      updateFailed: error => ({ type: types.UPDATE_FAILED, error }),
      delete: data => ({ type: types.DELETE_START, data }),
      deleteSuccess: data => ({ type: types.DELETE_SUCCESS, data }),
      deleteFailed: error => ({ type: types.DELETE_FAILED, error }),
    }),
  });

export default genericEntityDuck;
