import React from 'react';
import { FEATURE_TOGGLES } from 'App/ducks/featureDuck';
import FeatureToggle from 'sharedComponents/FeatureToggle';
import ThemeChange from './ThemeChange';

const Toasts = () => {
  return (
    <>
      <FeatureToggle name={FEATURE_TOGGLES.THEMING}>
        <ThemeChange />
      </FeatureToggle>
    </>
  );
};

export default Toasts;
