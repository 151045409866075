const colorsSequence = [
  '#FF4136',
  '#FF851B',
  '#FFDC00',
  '#2ECC40',
  '#0074D9',
  '#7FDBFF',
  '#B10DC9',
  '#AAAAAA',
  '#111111',
];

export const getColorByIndex = index => colorsSequence[index] || colorsSequence[0];

export const colors = {
  ACCENT: {
    ONE: '#88c666',
    TWO: '#217554',
  },
  GREY: {
    ONE: '#000001',
    TWO: '#333',
    THREE: '#3b3b3b',
    FOUR: '#434343',
  },
  LIGHT: {
    ONE: '#FFF',
    TWO: '#f5f5f5',
    THREE: '#BBB',
  },
  HEATMAP: {
    ONE: '#88c666',
    TWO: '#217554',
    THREE: '#773999',
  },
  ERROR: {
    WARNING: '#ffd602',
    CRITICAL: '#ff324d',
  },
};

export const colorIndexes = {
  ACCENT: {
    ONE: 'accent-3',
  },
  GREY: {
    ONE: 'grey-1',
    TWO: 'grey-2',
    THREE: 'grey-3',
    FOUR: 'grey-4',
  },
  LIGHT: {
    ONE: 'light-1',
    TWO: 'light-2',
  },
};
