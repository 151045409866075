const backend = (((typeof window !== 'undefined' && window) || {}).app || {}).BACKEND_HOST || '';
const server = `${backend}/metercloud-frontend-server`;

export const backendHost = import.meta.env.VITE_OVERRIDE_BACKEND_HOST || backend;
export const serverHost = import.meta.env.VITE_OVERRIDE_SERVER_HOST || server;
export const taskManagerHost = import.meta.env.VITE_TASK_MANAGER_HOST || '';

export const ghostwriter = `${serverHost}/ghostwriter-api/ghostwriter`;
export const datalakeGraphql = import.meta.env.VITE_DATALAKE_GRAPHQL_HOST;
export const datalakeApi = import.meta.env.VITE_DATALAKE_API_HOST;
export const openApiSpecLegacy = `${backendHost}/fs/resources/flows`;
export const openApiSpec = `${backendHost}`;
export const configuration = `${backendHost}/utilihive-frontend-configuration-server`;
export const resources = `${backendHost}/utilihive-integration-resource-registry/api`;
export const login = `${backendHost}/metercloud-integration-identity/api`;
export const insights = `${backendHost}/metercloud-integration-insights/api/v1`;
export const insightsV2 = `${backendHost}/metercloud-integration-insights/api/v2`;
export const adr = `${serverHost}/api/adr`;
export const taskManager = `${backendHost}/utilihive-task-manager`;
export const eve = `${serverHost}/eve-api`;
export const m3 = `${serverHost}/m3-api`;
